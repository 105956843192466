const ApiDataAssure = {
  EndPoint: process.env.REACT_APP_API_DATA_ASSURE
}

const ApiDnbConnector = {
  EndPoint: process.env.REACT_APP_DNB_API_URL
}

const ApiAdmin = {
  EndPoint: process.env.REACT_APP_API_ADMIN_URL
}

const ApiAdminPricebook = {
  EndPoint: process.env.REACT_APP_API_ADMIN_PRICEBOOK_URL
}

const ApiAdminInvoice = {
  EndPoint: process.env.REACT_APP_API_ADMIN_INVOICE_URL
}

const ApiAdminInsights = {
  EndPoint: process.env.REACT_APP_API_ADMIN_INSIGHTS_URL
}

const ApiNitorConnect = {
  EndPoint: process.env.REACT_APP_API_NITOR_CONNECT_URL
}

const ApiPii = {
  EndPoint: process.env.REACT_APP_PII_URL
}

const ApiDataAssureLogs = {
  EndPoint: process.env.REACT_APP_DATA_ASSURE_LOGS_URL
}

const ApiEmailService = {
  Endpoint: process.env.REACT_APP_API_EMAIL_READER_SERVICE
}

const Web = {
  EndPoint: process.env.REACT_APP_WEB_APP_URL
}

const ApiCoupa = {
  EndPoint: process.env.REACT_APP_API_COUPA_URL
}

const DABulkTemplate = {
  urlFile: process.env.REACT_APP_DA_BULK_TEMPLATE
}

const LoginUser = {
  RedirectEndPoint: `${Web.EndPoint}auth/login-page`,
  LogoutEndPoint: `${Web.EndPoint}`,
  ExpiredEndPoint: `${Web.EndPoint}error/sessionexpired`
}

const Auth0 = {
  Domain: process.env.REACT_APP_AUTH0_DOMAIN,
  ClientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  Audience: process.env.REACT_APP_AUTH0_AUDIENCE
}

const Insights = {
  Microsoft: {
    ClientId: process.env.REACT_APP_INSIGHTS_MICROSOFT_CLIENT_ID
  }
}

const InputDefault = '*************************'

const AddressValidaton = {
  AddressType: {
    extendedAddress: 'Extended Address',
    address: 'Address',
    generic: 'Generic Fields'
  }
}

const MessagesTypes = {
  info: 'Information',
  warning: 'Warning',
  error: 'Error',
  internal: 'Internal error'
}

const ConnectionTypes = {
  s3: 'S3',
  sharepoint: 'SharePoint',
  sapHana: 'Sap Hana',
  msSql: 'Microsoft SQL',
  decisionEngines: 'Decision Engines',
  SFTP: 'Secure File Transfer Protocol',
  sftp: 'Secure File Transfer Protocol'
}

const typeLoad = {
  historical: 'Historical',
  periodic: 'Periodic',
  scheduled: 'Scheduled Job'
}

const updateFrequency = {
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  oneTime: 'One time',
  everyNHours: 'Hours',
  everyNMinutes: 'Minutes'
}

const loadStatus = {
  created: 'Created',
  active: 'Active',
  inProgress: 'In Progress',
  completed: 'Completed',
  failed: 'Failed',
  success: 'Success',
  stopped: 'Stopped',
  scheduled: 'Scheduled'
}

const frequency = {
  days: 'Days',
  weeks: 'Weeks',
  months: 'Months',
  oneTime: 'One time',
  hours: 'Hours',
  minutes: 'Minutes'
}

const transactionStatus = {
  new: 'New',
  scheduled: 'Scheduled',
  stopped: 'Stopped',
  processing: 'Processing',
  completed: 'Completed',
  failed: 'Failed',
  deleted: 'Deleted'
}

const transactionLogType = {
  error: 'Error',
  info: 'Info',
  warning: 'Warning'
}

const piiValidationTypes = {
  bank: 'banksValidation',
  tin: 'Tin-Name Validation',
  file: 'fileValidation'
}
const piiValidationUpdateOptions = {
  bank: 'banksValidation',
  tin: 'Tin-Name Validation',
  all: 'All'
}
const aribaApiTypes = {
  analyticalReportingApi: 'Analytical Reporting',
  externalApprovalSourcingSupplierMngmtApi: 'External Approval API for Sourcing and Supplier Management',
  masterDataRetrievalSourcingApi: 'Master Data Retrieval API for Sourcing',
  aribaNetworkPurchaseOrdersApi: 'Ariba Network Purchase Order',
  aribaNetworkShipNoticesApi: 'Ariba Network Ship Notices',
  masterDataRetrievalProcurementApi: 'Master Data Retrieval API for Procurement'
}

const logLevelValues = {
  info: 'Information',
  error: 'Error',
  warning: 'Warning',
  debug: 'Debug'
}

const enabledApps = {
  insights: { freeTrial: true, name: 'Insights' },
  teams: { freeTrial: true, name: 'Nitor connect' },
  slp: { freeTrial: false, name: 'Data Assure' },
  intelligence: { freeTrial: true, name: 'Requisition Intelligence' },
  invoiceAI: { freeTrial: true, name: 'Invoice AI' },
  pricebook: { freeTrial: true, name: 'PriceBook' },
  assistant: {
    freeTrial: true,
    name: 'Assistant',
    subscriptions: [
      {
        id: 'freeTrial',
        label: 'Free Trial'
      },
      {
        id: 'freeTrial-extended',
        label: 'Free Trial Extended'
      }
    ]
  }
}

const validationTypes = {
  listMatchValidation: 'List Match Validation',
  addressValidation: 'Address Validation',
  tinValidation: 'Tin Validation',
  banksValidation: 'Bank Routing Number Validation'
}

const invoiceStatus = {
  new: 'New',
  ignored: 'Ignored',
  processing: 'Processing',
  failed: 'Failed',
  rejected: 'Rejected',
  valid: 'Valid',
  notValid: 'Not Valid',
  invoiceException: 'Invoice Exception',
  invoiceSupplierException: 'Supplier Exception',
  validating: 'Validating',
  validatedWithErrors: 'Validated With Errors',
  validated: 'Validated',
  exporting: 'Exporting',
  integrating: 'Integrating',
  integrated: 'Integrated',
  notIntegrated: 'Not Integrated',
  completed: 'Completed',
  notValidated: 'Not Validated',
  assigned: 'Assigned',
  partialAssigned: 'Partial Assigned',
  notAssigned: 'Not Assigned',
  sentToAdditionalInbox: 'Sent to additional Inbox',
  manuallyApproved: 'Manually Approved',
  manuallyIntegrated: 'Manually Integrated',
  sentToExternalSystem: 'Sent to External System',
  reconciling: 'Reconciling',
  forwarded: 'Forwarded',
  duplicateInvoice: 'Duplicate Invoice',
  /* Coupa Substatus */
  sentToCoupa: 'Sent to Coupa',
  submitted: 'Submitted',
  failedToSubmit: 'Failed to submit',
  voided: 'Void',
  approved: 'Approved',
  disputed: 'Disputed',
  /* Ariba Substatus */
  rejectedNotified: 'Rejected Notified',
  sentToAriba: 'Sent to Ariba',
  unprocessed: 'Unprocessed',
  rejecting: 'Rejecting',
  paying: 'Paying',
  paid: 'Paid',
  failedRejecting: 'Failed Rejecting',
  failedPaying: 'Failed Paying',
  canceled: 'Canceled',
  invalid: 'Invalid',
  awaitingExternalResolution: 'Awaiting External Resolution',
  failedExternalPush: 'Failed External Push',
  cCInvoiceToAN: 'CC Invoice To AN',
  submittedWithErrors: 'Submitted With Errors',
  awaitingReceipts: 'Awaiting Receipts',
  //submitted: 'Submitted',
  reconciled: 'Reconciled',
  composing: 'Composing',
  movingInvoiceApp: 'Changing Invoice System',
  failedToSend: 'Failed To Send'
}

const InvoiceProcess = {
  '*': 'Default',
  ocr: 'International',
  'ocr-global': 'Unassigned',
  mexicanValidation: 'Mexico',
  gtValidation: 'Guatemala',
  brValidation: 'Brazil',
  svValidation: 'El Salvador',
  northAmericaValidation: 'North America',
  fileImport: 'File Import',
  emeaValidation: 'EMEA',
  apacValidation: 'Asia Pacific',
  latamValidation: 'Latin America'
}

const InvoiceProcessFilter = {
  ocr: 'International',
  'ocr-global': 'Unassigned',
  mexicanValidation: 'Mexico',
  gtValidation: 'Guatemala',
  brValidation: 'Brazil',
  svValidation: 'El Salvador',
  northAmericaValidation: 'North America',
  fileImport: 'File Import',
  emeaValidation: 'EMEA',
  apacValidation: 'Asia Pacific',
  latamValidation: 'Latin America'
}

const statusHistory = {
  new: 'New',
  completed: 'Completed',
  error: 'Error',
  warning: 'Warning',
  ignored: 'Ignored',
  processing: 'Processing',
  rejected: 'Rejected',
  failed: 'Failed',
  reprocess: 'Reprocess',
  reconciling: 'Reconciling',
  invoiceException: 'Invoice Exception',
  invoiceSupplierException: 'Invoice Supplier Exception',
  movingInvoiceApp: 'Changing Invoice System',
  invoiceUpdated: 'Invoice Updated'
}

const AuthService = {
  Endpoint: process.env.REACT_APP_AUTH_SERVICE_DOMAIN
}

const ApiRouter = {
  Endpoint: process.env.REACT_APP_ROUTER_BACKEND_URL
}

const ViewsPermissions = {
  GENERAL: ['*'],
  TENANTS: {
    GENERALS: ['Manage.*'],
    CONFIGURATIONS: ['Manage.Tenants.*', 'Manage.Tenants.ListTenants']
  },
  USERS: {
    GENERALS: ['Manage.*'],
    CONFIGURATIONS: ['Manage.Users.*', 'Manage.Users.ListUsers']
  },
  SUPPORT: {
    GENERALS: ['Manage.*'],
    SUPPORT: ['Manage.Support.*', 'Manage.Support.ListTickets']
  },
  CONNECT: {
    GENERALS: ['Connect.*'],
    CONFIGURATIONS: ['Connect.Configurations.*', 'Connect.Configurations.ListConfigurations'],
    DEFAULT_CONNECTION: ['Connect.DefaultConnections.*', 'Connect.DefaultConnections.SetDefaultConnection']
  },
  DATA_ASSURE: {
    GENERALS: ['DataAssure.*'],
    CONFIGURATIONS: ['DataAssure.Configurations.*', 'DataAssure.Configurations.ListConfigurations'],
    LOGS: ['DataAssure.Logs.*', 'DataAssure.Logs.ListLogs']
  },
  INSIGHTS: {
    GENERALS: ['Insights.*'],
    CONFIGURATIONS: ['Insights.Configurations.*', 'Insights.Configurations.ListConfigurations']
  },
  INTELLIGENCE: {
    GENERALS: ['Intelligence.*'],
    CONFIGURATIONS: ['Intelligence.Configurations.*', 'Intelligence.Configurations.ListConfigurations']
  },
  INVOICEAI: {
    GENERALS: ['InvoiceAI.*'],
    INVOICES: ['InvoiceAI.Invoices.*', 'InvoiceAI.Invoices.ListInvoices'],
    OCR_INVOICES: ['InvoiceAI.OCRInvoices.*', 'InvoiceAI.OCRInvoices.ListOCRInvoices'],
    EMAILS: ['InvoiceAI.Emails.*', 'InvoiceAI.Emails.ListEmails', 'InvoiceAI.Emails.Details'],
    IMPORT_INVOICES: ['InvoiceAI.ImportInvoices.*', 'InvoiceAI.ImportInvoices.ImportFiles'],
    CONFIGURATIONS: ['InvoiceAI.Configurations.*'],
    MASTERDATA: ['InvoiceAI.MasterData.*', 'InvoiceAI.MasterData.DownloadTypes'],
    VALIDATION_RULES: [
      'InvoiceAI.ValidationRules.GetValidationRules',
      'InvoiceAI.ValidationRules.GetValidationRule',
      'InvoiceAI.ValidationRules.SaveValidationRule'
    ],
    RULE_ENGINE: [
      'InvoiceAI.InternalAPI.RuleEngine.*',
      'InvoiceAI.InternalAPI.RuleEngine.BusinessRules.*',
      'InvoiceAI.InternalAPI.RuleEngine.BusinessRules.Create',
      'InvoiceAI.InternalAPI.RuleEngine.BusinessRules.Update',
      'InvoiceAI.InternalAPI.RuleEngine.BusinessRulesItems.Read',
      'InvoiceAI.InternalAPI.RuleEngine.BusinessRules.Read'
    ]
  },
  PII: {
    GENERALS: ['Pii.*'],
    INDEX: ['Pii.ListEmails']
  },
  DNB: {
    GENERALS: ['DnB.*'],
    MATCH: ['DnB.Match']
  },
  ROUTER: {
    GENERALS: ['Router.*'],
    CONFIGURATIONS: ['Router.Configurations.*', 'Router.Configurations.ListConfigurations']
  },
  CUSTOM: {
    GENERALS: ['Custom.*'],
    DATA_ASSURE_UPLOAD: ['Custom.DataAssure.Menu.BatchUpload.*'],
    PAYMENT_RECEIPTS: ['Custom.InvoiceAI.*', 'Custom.InvoiceAI.PaymentReceipts.*', 'Custom.InvoiceAI.PaymentReceipts.Read'],
    IMPORT_INVOICES: [
      'Custom.InvoiceAI.*',
      'Custom.InvoiceAI.ImportInvoices.*',
      'Custom.InvoiceAI.ImportInvoices.list',
      'Custom.InvoiceAI.ImportInvoices.details',
      'Custom.InvoiceAI.ImportInvoices.upload'
    ],
    INSIGHTS: ['Custom.Insights.*'],
    PO_EDITOR: [
      'Custom.InvoiceAI.*',
      'Custom.InvoiceAI.MasterData.*',
      'Custom.InvoiceAI.MasterData.PurchaseOrder.*',
      'Custom.InvoiceAI.MasterData.PurchaseOrder.List',
      'Custom.InvoiceAI.MasterData.PurchaseOrder.Create',
      'Custom.InvoiceAI.MasterData.PurchaseOrder.Read',
      'Custom.InvoiceAI.MasterData.PurchaseOrder.Update'
    ],
    PRICEBOOK: {
      GENERALS: ['Pricebook.*'],
      CONFIGURATIONS: ['Pricebook.Configurations.*', 'Pricebook.Configurations.ListConfigurations']
    }
  },
  PRICE_VERIFY: {
    GENERALS: ['PriceVerify.*'],
    DOWNLOAD_HISTORY: ['PriceVerify.DownloadHistory']
  }
}

const invoiceProcessType = {
  mexicanValidation: 'mexicanValidation',
  ocr: 'ocr',
  eInvoice: 'e-invoice'
}

const invoiceTitles = {
  sourcingMasterData: 'Master Data Retrieval Sourcing',
  procurementMasterData: 'Master Data Retrieval Procurement',
  downstream: 'Downstream',
  procurement: 'Procurement'
}

const insightsTitles = {
  procurement: 'Procurement',
  analytics: 'Analytics',
  sourcing: 'Sourcing',
  upstream: 'Upstream',
  downstream: 'Downstream',
  spendVisibility: 'Spend Visibility'
}

// Check again
const insightsDeluxeTitles = {
  analyticalReportingApi: 'Analytics',
  operationalReportingProcurementApi: 'Procurement',
  operationalReportingSourcingApi: 'Sourcing',
  sourcingApi: 'Upstream',
  buyerItkApi: 'Downstream',
  spendVisibility: 'Spend Visibility',
  core: 'Core',
  purchaseOrderApi: 'Purchase Order',
  businessPartnerApi: 'Business Partner',
  supplierDataPaginationApi: 'Supplier Data With Pagination',
  restConnectionApi: 'REST',
  soapConnectionApi: 'SOAP',
  raasApi: 'RaaS',
  aribaNetworkPurchaseOrdersBuyer: 'Ariba Network Purchase Order',
  aribaNetworkInvoiceHeaderDataExtraction: 'Invoice Header'
}

const entities = {
  operationalReportingProcurementApi: {
    sap: [
      {
        group: 'operationalReportingProcurementApi',
        entity: 'Requisition_SAP_updatedRange'
      },
      {
        group: 'operationalReportingProcurementApi',
        entity: 'Invoice_SAP_updatedRange'
      },
      {
        group: 'operationalReportingProcurementApi',
        entity: 'InvoiceReconciliation_SAP_updatedRange'
      },
      {
        group: 'operationalReportingProcurementApi',
        entity: 'Receipt_SAP_updatedRange'
      },
      {
        group: 'operationalReportingProcurementApi',
        entity: 'ERPOrder_SAP_updatedRange'
      },
      {
        group: 'operationalReportingProcurementApi',
        entity: 'DirectOrder_SAP_updatedRange'
      },
      {
        group: 'operationalReportingProcurementApi',
        entity: 'CopyOrder_SAP_updatedRange'
      },
      {
        group: 'operationalReportingProcurementApi',
        entity: 'Requisition_SAP_createdRange'
      },
      {
        group: 'operationalReportingProcurementApi',
        entity: 'Invoice_SAP_createdRange'
      },
      {
        group: 'operationalReportingProcurementApi',
        entity: 'InvoiceReconciliation_SAP_createdRange'
      },
      {
        group: 'operationalReportingProcurementApi',
        entity: 'Receipt_SAP_createdRange'
      },
      {
        group: 'operationalReportingProcurementApi',
        entity: 'ERPOrder_SAP_createdRange'
      },
      {
        group: 'operationalReportingProcurementApi',
        entity: 'DirectOrder_SAP_createdRange'
      },
      {
        group: 'operationalReportingProcurementApi',
        entity: 'CopyOrder_SAP_createdRange'
      },
      {
        group: 'operationalReportingProcurementApi',
        entity: 'Requisition_SAP_updatedRange_v2'
      },
      {
        group: 'operationalReportingProcurementApi',
        entity: 'Invoice_SAP_updatedRange_v2'
      },
      {
        group: 'operationalReportingProcurementApi',
        entity: 'Requisition_SAP_createdRange_v2'
      },
      {
        group: 'operationalReportingProcurementApi',
        entity: 'Invoice_SAP_createdRange_v2'
      },
      {
        group: 'operationalReportingProcurementApi',
        entity: 'Contract_SAP_createdRange_v1'
      },
      {
        group: 'operationalReportingProcurementApi',
        entity: 'Contract_SAP_updatedRange_v1'
      }
    ],
    generic: [
      {
        entity: 'Invoice_Generic_updatedRange',
        group: 'operationalReportingProcurementApi'
      },
      {
        entity: 'DirectOrder_Generic_createdRange',
        group: 'operationalReportingProcurementApi'
      },
      {
        entity: 'CopyOrder_Generic_createdRange',
        group: 'operationalReportingProcurementApi'
      },
      {
        entity: 'Requisition_Generic_updatedRange',
        group: 'operationalReportingProcurementApi'
      },
      {
        entity: 'Requisition_Generic_createdRange',
        group: 'operationalReportingProcurementApi'
      },
      {
        entity: 'Invoice_Generic_createdRange',
        group: 'operationalReportingProcurementApi'
      },
      {
        entity: 'InvoiceReconciliation_Generic_createdRange',
        group: 'operationalReportingProcurementApi'
      },
      {
        entity: 'Receipt_Generic_createdRange',
        group: 'operationalReportingProcurementApi'
      },
      {
        entity: 'ERPOrder_Generic_createdRange',
        group: 'operationalReportingProcurementApi'
      },
      {
        entity: 'InvoiceReconciliation_Generic_updatedRange',
        group: 'operationalReportingProcurementApi'
      },
      {
        entity: 'Receipt_Generic_updatedRange',
        group: 'operationalReportingProcurementApi'
      },
      {
        entity: 'ERPOrder_Generic_updatedRange',
        group: 'operationalReportingProcurementApi'
      },
      {
        entity: 'DirectOrder_Generic_updatedRange',
        group: 'operationalReportingProcurementApi'
      },
      {
        entity: 'CopyOrder_Generic_updatedRange',
        group: 'operationalReportingProcurementApi'
      },
      {
        entity: 'Requisition_Generic_createdRange_v2',
        group: 'operationalReportingProcurementApi'
      },
      {
        entity: 'Invoice_Generic_createdRange_v2',
        group: 'operationalReportingProcurementApi'
      },
      {
        entity: 'Requisition_Generic_updatedRange_v2',
        group: 'operationalReportingProcurementApi'
      },
      {
        entity: 'Invoice_Generic_updatedRange_v2',
        group: 'operationalReportingProcurementApi'
      },
      {
        entity: 'Contract_Generic_createdRange_v1',
        group: 'operationalReportingProcurementApi'
      },
      {
        entity: 'Contract_Generic_updatedRange_v1',
        group: 'operationalReportingProcurementApi'
      }
    ]
  },
  analyticalReportingApi: {
    sap: [
      {
        entity: 'Relish_ProjectFactSystemView_v1',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'Relish_SourcingProjectFactSystemView_v1',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'Relish_SourcingRequestFactSystemView_v1',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'Relish_ProjectGroupFactSystemView_v1',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'Relish_EventParticipationFactSystemView_v1',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'Relish_GeneralContractWorkspaceFactSystemView_v1',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'Relish_SourcingProjectView_v1',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'AdvancePaymentFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'UserAbilityFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SupplierFactFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'S4ApprovalFlowFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ProjectFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SourcingProjectFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SourcingRequestFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SPMProjectFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SQMProjectFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'DocumentFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ProjectTaskFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ProjectGroupFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SavingsFormFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SavingsAllocationDetailsFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'BaseConsultingCategoryLineItemFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'CommodityExperienceDataFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'CombinedPriceIndexFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'AdvancePaymentIncludesLineLevelFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ApprovalHistoryFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ApprovalsFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'PendingApprovalFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'BudgetLineFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FinancialBudgetFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'BusinessContactFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ChargeExceptionsFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ERHeaderFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ERLineItemFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ERHeaderViolationFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ERLineViolationFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'AttendeeFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ChargeFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'CatalogReportingEntryFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'CollaborationRequestFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ProposalLineFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractFactFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractLineItemFactFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ForecastedContractSpendFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'GeneralContractWorkspaceFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractAmendmentDetailsFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractWorkspaceFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractRequestFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SalesContractWorkspaceFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SalesContractRequestFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'InternalContractWorkspaceFactFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractClauseFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractItemFactFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractLineItemDocumentFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ItemAttributesFactFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractorProjectFactFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractorEngagementProjectFactFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'OrderConfirmationFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'BaseMarketResearchCategoryLineItemFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'PaymentFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'InvoiceHeaderExceptionFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'InvoiceExceptionsFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'InvLineExceptionFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'InvoiceLineItemFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SSPRejectedInvoiceFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'OneTimeVendorsFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SSPInvoiceLineItemFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SSPPrereconciledInvoiceLineItemFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'RequisitionLineItemFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'RequisitionHeldLineItemFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ReceiptFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'POLineItemFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SSPPOLineItemFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FeedbackRuleFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FinancialsFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'BaseGenericCategoryLineItemFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ServicesProcurementRequestFactFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SRSurveyResponseFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ServiceSheetFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ServicesProcurementWorkspaceFactFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'PODeliveryFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'EventSummaryFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'EventParticipationFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'RfxItemSummaryFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SupplierParticipationFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ScorecardFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SurveyParticipationFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SurveyResponseFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'RequestFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SMProjectFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SupplierRequestProjectFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SupplierRegistrationProjectFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SMSurveyResponseFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SRProjectFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SRProjectTaskFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SRProjectTaskApprovalFlowFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SRProjectSurveyResponseFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'CostCenterDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SupplierDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'UNSPSCDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'CommodityDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SupplierDiversityFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SupplierGreenCertificateFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'TemporaryLaborFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'TimeSheetFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ExpenseSheetFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'UserActivityFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'LocationDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'RegionDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'OrganizationDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ProcurementSystemDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SourceSystemDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'PartDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'UOMDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'AccountDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'UserDataDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'UNSPSC2DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'CompanyCodeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'AssetDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'PCardDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ERPCommodityDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'CompanySiteDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'RevenueAmountRangeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'AmountRangeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'PercentRangeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'TimeRangeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'NumberLinesRangeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'TimeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'CompanyDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ProcurementUnitDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ProductDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'AccountingProjectDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'AccountingRegionDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SubAccountDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'StatisticsCodeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'InternalOrderDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'NetworkDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ActivityNumberDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractorDimDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ACMProductDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'WorkspaceFolderDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'CommodityEscalationDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FeedbackRequestDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'TaxCodeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'RemittanceLocationDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ServiceCategoryDefinitionDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ProjectInfoDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'VersionedProjectInfoDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'EventTypeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ProcessDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ProjectTaskNameDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SavingsFormInfoDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'CityDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'VendorDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ExpenseTypeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ViolationPolicyTypeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'LineViolationDetailsDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'BenchmarkPeriodTypeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'EventDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SurveyQuestionDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SurveyDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SMProjectInfoDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SMSurveyTemplateQuestionDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'InvoiceExceptionTypeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension1DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension2DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension3DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension4DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension5DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension6DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension7DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension8DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension9DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension10DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension11DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension12DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension13DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension14DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ScorecardKPIDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ScorecardDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SRProjectInfoDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'JobTypeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractorProjectCodeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractorProjectTaskDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SSPPreInvLineItemFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'TestContracts',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'TestContracts2',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'TestContracts3',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SLContractReportTest',
        group: 'analyticalReportingApi'
      }
    ],
    generic: [
      {
        entity: 'AdvancePaymentFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'UserAbilityFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SupplierFactFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'S4ApprovalFlowFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ProjectFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SourcingProjectFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SourcingRequestFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SPMProjectFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SQMProjectFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'DocumentFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ProjectTaskFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ProjectGroupFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SavingsFormFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SavingsAllocationDetailsFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'BaseConsultingCategoryLineItemFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'CommodityExperienceDataFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'CombinedPriceIndexFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'AdvancePaymentIncludesLineLevelFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ApprovalHistoryFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ApprovalsFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'PendingApprovalFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'BudgetLineFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FinancialBudgetFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'BusinessContactFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ChargeExceptionsFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ERHeaderFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ERLineItemFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ERHeaderViolationFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ERLineViolationFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'AttendeeFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ChargeFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'CatalogReportingEntryFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'CollaborationRequestFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ProposalLineFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractFactFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractLineItemFactFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ForecastedContractSpendFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'GeneralContractWorkspaceFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractAmendmentDetailsFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractWorkspaceFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractRequestFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SalesContractWorkspaceFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SalesContractRequestFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'InternalContractWorkspaceFactFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractClauseFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractItemFactFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractLineItemDocumentFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ItemAttributesFactFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractorProjectFactFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractorEngagementProjectFactFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'OrderConfirmationFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'BaseMarketResearchCategoryLineItemFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'PaymentFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'InvoiceHeaderExceptionFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'InvoiceExceptionsFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'InvLineExceptionFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'InvoiceLineItemFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SSPRejectedInvoiceFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'OneTimeVendorsFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SSPInvoiceLineItemFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SSPPrereconciledInvoiceLineItemFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'RequisitionLineItemFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'RequisitionHeldLineItemFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ReceiptFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'POLineItemFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SSPPOLineItemFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FeedbackRuleFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FinancialsFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'BaseGenericCategoryLineItemFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ServicesProcurementRequestFactFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SRSurveyResponseFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ServiceSheetFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ServicesProcurementWorkspaceFactFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'PODeliveryFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'EventSummaryFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'EventParticipationFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'RfxItemSummaryFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SupplierParticipationFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ScorecardFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SurveyParticipationFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SurveyResponseFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'RequestFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SMProjectFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SupplierRequestProjectFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SupplierRegistrationProjectFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SMSurveyResponseFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SRProjectFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SRProjectTaskFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SRProjectTaskApprovalFlowFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SRProjectSurveyResponseFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'CostCenterDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SupplierDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'UNSPSCDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'CommodityDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SupplierDiversityFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SupplierGreenCertificateFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'TemporaryLaborFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'TimeSheetFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ExpenseSheetFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'UserActivityFactSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'LocationDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'RegionDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'OrganizationDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ProcurementSystemDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SourceSystemDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'PartDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'UOMDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'AccountDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'UserDataDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'UNSPSC2DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'CompanyCodeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'AssetDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'PCardDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ERPCommodityDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'CompanySiteDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'RevenueAmountRangeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'AmountRangeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'PercentRangeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'TimeRangeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'NumberLinesRangeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'TimeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'CompanyDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ProcurementUnitDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ProductDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'AccountingProjectDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'AccountingRegionDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SubAccountDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'StatisticsCodeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'InternalOrderDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'NetworkDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ActivityNumberDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractorDimDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ACMProductDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'WorkspaceFolderDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'CommodityEscalationDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FeedbackRequestDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'TaxCodeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'RemittanceLocationDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ServiceCategoryDefinitionDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ProjectInfoDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'VersionedProjectInfoDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'EventTypeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ProcessDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ProjectTaskNameDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SavingsFormInfoDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'CityDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'VendorDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ExpenseTypeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ViolationPolicyTypeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'LineViolationDetailsDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'BenchmarkPeriodTypeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'EventDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SurveyQuestionDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SurveyDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SMProjectInfoDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SMSurveyTemplateQuestionDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'InvoiceExceptionTypeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension1DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension2DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension3DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension4DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension5DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension6DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension7DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension8DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension9DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension10DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension11DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension12DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension13DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'FlexDimension14DimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ScorecardKPIDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ScorecardDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SRProjectInfoDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'JobTypeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractorProjectCodeDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'ContractorProjectTaskDimSystemView',
        group: 'analyticalReportingApi'
      },
      {
        entity: 'SSPPreInvLineItemFactSystemView',
        group: 'analyticalReportingApi'
      }
    ]
  },
  operationalReportingSourcingApi: {
    sap: [
      {
        entity: 'Relish_ContractWorkspaceFactSystemView_v1',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'Relish_ContractRequestFactSystemView_v1',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'Relish_ContractLineItemFactFactSystemView_v1',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'Relish_OrganizationSourcingSystemView_v1',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'Relish_UserSourcingSystemView_v1',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'Relish_ContractWorkspaceView_v1',
        group: 'operationalReportingSourcingApi'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'UserSourcingSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'OrganizationSourcingSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'TaskSourcingSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'DocumentTaskSourcingSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'AuditEntrySourcingSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ProjectAuditInfoSourcingSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SourcingProjectSourcingSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'RFXDocumentSourcingSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'AdvancePaymentFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'UserAbilityFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SupplierFactFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'S4ApprovalFlowFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ProjectFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SourcingProjectFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SourcingRequestFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SPMProjectFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SQMProjectFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'DocumentFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ProjectTaskFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ProjectGroupFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SavingsFormFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SavingsAllocationDetailsFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'BaseConsultingCategoryLineItemFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'CommodityExperienceDataFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'CombinedPriceIndexFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'CustomBenchmarkingFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'AdvancePaymentIncludesLineLevelFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ApprovalHistoryFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ApprovalsFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'PendingApprovalFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'BudgetLineFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'FinancialBudgetFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'BusinessContactFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ChargeExceptionsFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ERHeaderFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ERLineItemFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ERHeaderViolationFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ERLineViolationFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'AttendeeFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ChargeFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'CatalogReportingEntryFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'CollaborationRequestFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ProposalLineFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'CustomFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ContractsDFormFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ContractsDFormDetailFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'EFormFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ConsultingCategoryLineItemFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ContractFactFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ContractLineItemFactFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ForecastedContractSpendFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'GeneralContractWorkspaceFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ContractAmendmentDetailsFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ContractWorkspaceFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ContractRequestFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SalesContractWorkspaceFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SalesContractRequestFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'InternalContractWorkspaceFactFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ContractClauseFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ContractItemFactFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ContractLineItemDocumentFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ItemAttributesFactFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ContractorProjectFactFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ContractorEngagementProjectFactFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'OrderConfirmationFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'GenericCategoryLineItemFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'BaseMarketResearchCategoryLineItemFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'MarketResearchCategoryLineItemFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'EFormDetailFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'PaymentFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'InvoiceHeaderExceptionFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'InvoiceExceptionsFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'InvLineExceptionFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'InvoiceLineItemFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SSPRejectedInvoiceFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'OneTimeVendorsFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SSPInvoiceLineItemFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SSPPrereconciledInvoiceLineItemFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'RequisitionLineItemFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'RequisitionHeldLineItemFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ReceiptFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'POLineItemFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SSPPOLineItemFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'FeedbackRuleFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'FinancialsFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'BaseGenericCategoryLineItemFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ServicesProcurementRequestFactFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SRSurveyResponseFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ServiceSheetFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ServicesProcurementWorkspaceFactFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'PODeliveryFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'EventSummaryFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'EventParticipationFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'RfxItemSummaryFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SupplierParticipationFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ScorecardFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SurveyParticipationFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SurveyResponseFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'RequestFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SMProjectFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SupplierRequestProjectFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SupplierRegistrationProjectFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SMSurveyResponseFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SRProjectFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SRProjectTaskFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SRProjectTaskApprovalFlowFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SRProjectSurveyResponseFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'CostCenterDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SupplierDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'UNSPSCDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'CommodityDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SupplierDiversityFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SupplierGreenCertificateFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'TemporaryLaborFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'TimeSheetFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ExpenseSheetFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'DerivedTemporaryLaborCategoryLineItemFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'UserActivityFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'LocationDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'RegionDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'OrganizationDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ProcurementSystemDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SourceSystemDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ContractDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'PartDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'UOMDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'AccountDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'UserDataDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'UNSPSC2DimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'CompanyCodeDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'AssetDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'PCardDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ERPCommodityDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'CompanySiteDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'RevenueAmountRangeDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'AmountRangeDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'PercentRangeDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'TimeRangeDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'NumberLinesRangeDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'TimeDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'CompanyDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ProcurementUnitDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ProductDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'AccountingProjectDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'AccountingRegionDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SubAccountDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'StatisticsCodeDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'InternalOrderDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'NetworkDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ActivityNumberDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ContractorDimDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ACMProductDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'WorkspaceFolderDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'CommodityEscalationDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'FeedbackRequestDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'TaxCodeDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'RemittanceLocationDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ServiceCategoryDefinitionDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ProjectInfoDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'VersionedProjectInfoDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'EventTypeDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ProcessDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ProjectTaskNameDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SavingsFormInfoDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'CityDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'VendorDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ExpenseTypeDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ViolationPolicyTypeDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'LineViolationDetailsDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'BenchmarkPeriodTypeDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'EventDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SurveyQuestionDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SurveyDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SMProjectInfoDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SMSurveyTemplateQuestionDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'InvoiceExceptionTypeDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'FlexDimension1DimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'FlexDimension2DimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'FlexDimension3DimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'FlexDimension4DimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'FlexDimension5DimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'FlexDimension6DimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'FlexDimension7DimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'FlexDimension8DimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'FlexDimension9DimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'FlexDimension10DimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'FlexDimension11DimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'FlexDimension12DimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'FlexDimension13DimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'FlexDimension14DimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ScorecardKPIDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ScorecardDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SRProjectInfoDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'JobTypeDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ContractorProjectCodeDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'ContractorProjectTaskDimSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SSPPreInvLineItemFactSystemView'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'TestContracts'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'TestContracts2'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'TestContracts3'
      },
      {
        group: 'operationalReportingSourcingApi',
        entity: 'SLContractReportTest'
      }
    ],
    generic: [
      {
        entity: 'UserSourcingSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'OrganizationSourcingSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'TaskSourcingSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'DocumentTaskSourcingSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'AuditEntrySourcingSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ProjectAuditInfoSourcingSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SourcingProjectSourcingSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'RFXDocumentSourcingSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'AdvancePaymentFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'UserAbilityFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SupplierFactFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'S4ApprovalFlowFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ProjectFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SourcingProjectFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SourcingRequestFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SPMProjectFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SQMProjectFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'DocumentFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ProjectTaskFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ProjectGroupFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SavingsFormFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SavingsAllocationDetailsFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'BaseConsultingCategoryLineItemFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'CommodityExperienceDataFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'CombinedPriceIndexFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'CustomBenchmarkingFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'AdvancePaymentIncludesLineLevelFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ApprovalHistoryFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ApprovalsFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'PendingApprovalFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'BudgetLineFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'FinancialBudgetFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'BusinessContactFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ChargeExceptionsFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ERHeaderFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ERLineItemFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ERHeaderViolationFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ERLineViolationFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'AttendeeFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ChargeFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'CatalogReportingEntryFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'CollaborationRequestFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ProposalLineFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'CustomFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ContractsDFormFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ContractsDFormDetailFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'EFormFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ConsultingCategoryLineItemFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ContractFactFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ContractLineItemFactFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ForecastedContractSpendFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'GeneralContractWorkspaceFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ContractAmendmentDetailsFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ContractWorkspaceFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ContractRequestFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SalesContractWorkspaceFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SalesContractRequestFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'InternalContractWorkspaceFactFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ContractClauseFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ContractItemFactFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ContractLineItemDocumentFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ItemAttributesFactFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ContractorProjectFactFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ContractorEngagementProjectFactFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'OrderConfirmationFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'GenericCategoryLineItemFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'BaseMarketResearchCategoryLineItemFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'MarketResearchCategoryLineItemFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'EFormDetailFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'PaymentFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'InvoiceHeaderExceptionFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'InvoiceExceptionsFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'InvLineExceptionFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'InvoiceLineItemFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SSPRejectedInvoiceFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'OneTimeVendorsFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SSPInvoiceLineItemFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SSPPrereconciledInvoiceLineItemFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'RequisitionLineItemFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'RequisitionHeldLineItemFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ReceiptFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'POLineItemFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SSPPOLineItemFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'FeedbackRuleFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'FinancialsFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'BaseGenericCategoryLineItemFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ServicesProcurementRequestFactFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SRSurveyResponseFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ServiceSheetFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ServicesProcurementWorkspaceFactFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'PODeliveryFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'EventSummaryFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'EventParticipationFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'RfxItemSummaryFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SupplierParticipationFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ScorecardFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SurveyParticipationFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SurveyResponseFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'RequestFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SMProjectFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SupplierRequestProjectFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SupplierRegistrationProjectFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SMSurveyResponseFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SRProjectFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SRProjectTaskFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SRProjectTaskApprovalFlowFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SRProjectSurveyResponseFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'CostCenterDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SupplierDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'UNSPSCDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'CommodityDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SupplierDiversityFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SupplierGreenCertificateFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'TemporaryLaborFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'TimeSheetFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ExpenseSheetFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'DerivedTemporaryLaborCategoryLineItemFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'UserActivityFactSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'LocationDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'RegionDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'OrganizationDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ProcurementSystemDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SourceSystemDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ContractDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'PartDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'UOMDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'AccountDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'UserDataDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'UNSPSC2DimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'CompanyCodeDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'AssetDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'PCardDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ERPCommodityDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'CompanySiteDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'RevenueAmountRangeDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'AmountRangeDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'PercentRangeDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'TimeRangeDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'NumberLinesRangeDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'TimeDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'CompanyDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ProcurementUnitDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ProductDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'AccountingProjectDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'AccountingRegionDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SubAccountDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'StatisticsCodeDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'InternalOrderDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'NetworkDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ActivityNumberDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ContractorDimDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ACMProductDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'WorkspaceFolderDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'CommodityEscalationDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'FeedbackRequestDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'TaxCodeDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'RemittanceLocationDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ServiceCategoryDefinitionDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ProjectInfoDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'VersionedProjectInfoDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'EventTypeDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ProcessDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ProjectTaskNameDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SavingsFormInfoDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'CityDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'VendorDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ExpenseTypeDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ViolationPolicyTypeDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'LineViolationDetailsDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'BenchmarkPeriodTypeDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'EventDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SurveyQuestionDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SurveyDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SMProjectInfoDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SMSurveyTemplateQuestionDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'InvoiceExceptionTypeDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'FlexDimension1DimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'FlexDimension2DimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'FlexDimension3DimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'FlexDimension4DimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'FlexDimension5DimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'FlexDimension6DimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'FlexDimension7DimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'FlexDimension8DimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'FlexDimension9DimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'FlexDimension10DimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'FlexDimension11DimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'FlexDimension12DimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'FlexDimension13DimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'FlexDimension14DimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ScorecardKPIDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ScorecardDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SRProjectInfoDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'JobTypeDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ContractorProjectCodeDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'ContractorProjectTaskDimSystemView',
        group: 'operationalReportingSourcingApi'
      },
      {
        entity: 'SSPPreInvLineItemFactSystemView',
        group: 'operationalReportingSourcingApi'
      }
    ]
  },
  masterDataRetrievalProcurementApi: {
    sap: [
      {
        group: 'masterDataRetrievalProcurementApi',
        entity: 'Export Units Of Measure (CSV)'
      },
      {
        group: 'masterDataRetrievalProcurementApi',
        entity: 'Export Supplier Users and Organizations'
      },
      {
        group: 'masterDataRetrievalProcurementApi',
        entity: 'Export Supplier Users and Organizations (CSV)'
      },
      {
        group: 'masterDataRetrievalProcurementApi',
        entity: 'Export Supplier Users'
      },
      {
        group: 'masterDataRetrievalProcurementApi',
        entity: 'Export Supplier Users (CSV)'
      },
      {
        group: 'masterDataRetrievalProcurementApi',
        entity: 'Export Supplier Profiles'
      },
      {
        group: 'masterDataRetrievalProcurementApi',
        entity: 'Export Supplier Organizations (CSV)'
      },
      {
        group: 'masterDataRetrievalProcurementApi',
        entity: 'Export Region Codes (CSV)'
      },
      {
        group: 'masterDataRetrievalProcurementApi',
        entity: 'Export Organization with vendor keys (csv)'
      },
      {
        group: 'masterDataRetrievalProcurementApi',
        entity: 'Export Organizations (to csv file)'
      },
      {
        group: 'masterDataRetrievalProcurementApi',
        entity: 'Export Organization IDs (zipped CSV)'
      },
      {
        group: 'masterDataRetrievalProcurementApi',
        entity: 'Export Locales (CSV)'
      },
      {
        group: 'masterDataRetrievalProcurementApi',
        entity: 'Export Languages (CSV)'
      },
      {
        group: 'masterDataRetrievalProcurementApi',
        entity: 'Export Enterprise Users CSV'
      },
      {
        group: 'masterDataRetrievalProcurementApi',
        entity: 'Export Enterprise Users'
      },
      {
        group: 'masterDataRetrievalProcurementApi',
        entity: 'Export Enterprise Organizations Hierarchy (CSV)'
      },
      {
        group: 'masterDataRetrievalProcurementApi',
        entity: 'Export Deactivated Users (CSV)'
      },
      {
        group: 'masterDataRetrievalProcurementApi',
        entity: 'Export Currencies (CSV)'
      },
      {
        group: 'masterDataRetrievalProcurementApi',
        entity: 'Export Countries (CSV)'
      },
      {
        group: 'masterDataRetrievalProcurementApi',
        entity: 'Export Contract Header Data (ZIP)'
      },
      {
        group: 'masterDataRetrievalProcurementApi',
        entity: 'Export Addresses (CSV)'
      }
    ]
  },
  downstream: {
    sap: [
      {
        group: 'Downstream',
        entity: 'User Consolidated Export'
      },
      {
        group: 'Downstream',
        entity: 'Supplier Location Consolidated Export'
      },
      {
        group: 'Downstream',
        entity: 'Supplier Consolidated Export'
      },
      {
        group: 'Downstream',
        entity: 'Remittance Location Consolidated Export'
      },
      {
        group: 'Downstream',
        entity: 'Payment Terms Consolidated Export'
      },
      {
        group: 'Downstream',
        entity: 'Group Mapping Consolidated Export'
      },
      {
        group: 'Downstream',
        entity: 'Export Suppliers v2'
      },
      {
        group: 'Downstream',
        entity: 'Export Supplier Supplement Data'
      },
      {
        group: 'Downstream',
        entity: 'Export Suppliers'
      },
      {
        group: 'Downstream',
        entity: 'Export Supplier Organizations'
      },
      {
        group: 'Downstream',
        entity: 'Export Supplier Location Remittance Information'
      },
      {
        group: 'Downstream',
        entity: 'Export Supplier Location Contacts'
      },
      {
        group: 'Downstream',
        entity: 'Export Supplier Bank Payment Locations'
      },
      {
        group: 'Downstream',
        entity: 'Export Receipt Status'
      },
      {
        group: 'Downstream',
        entity: 'Export Receipts'
      },
      {
        group: 'Downstream',
        entity: 'Export Purchase Orders'
      },
      {
        group: 'Downstream',
        entity: 'Export Purchase Order Close Status'
      },
      {
        group: 'Downstream',
        entity: 'Export Purchase Order'
      },
      {
        group: 'Downstream',
        entity: 'Export Preferred Supplier Mappings'
      },
      {
        group: 'Downstream',
        entity: 'Export PCard Limits'
      },
      {
        group: 'Downstream',
        entity: 'Export PCard Accounting Association'
      },
      {
        group: 'Downstream',
        entity: 'Export Payment Requests with Advance Payments'
      },
      {
        group: 'Downstream',
        entity: 'Export Kits'
      },
      {
        group: 'Downstream',
        entity: 'Export Invoices'
      },
      {
        group: 'Downstream',
        entity: 'Export Financial Extract'
      },
      {
        group: 'Downstream',
        entity: 'Export Enterprise Users Browser Info'
      },
      {
        group: 'Downstream',
        entity: 'Export Enterprise Users'
      },
      {
        group: 'Downstream',
        entity: 'Export Deactivated Enterprise Users'
      },
      {
        group: 'Downstream',
        entity: 'Export Contractors'
      },
      {
        group: 'Downstream',
        entity: 'Export Charge Payment Requests'
      },
      {
        group: 'Downstream',
        entity: 'Export Change Purchase Order'
      },
      {
        group: 'Downstream',
        entity: 'Export Cancel Purchase Order'
      },
      {
        group: 'Downstream',
        entity: 'Export Addresses'
      },
      {
        group: 'Downstream',
        entity: 'Export Cancel Advance Payments'
      },
      {
        group: 'Downstream',
        entity: 'Export Advance Payments'
      },
      {
        group: 'Downstream',
        entity: 'Export Accrual Transactions'
      },
      {
        group: 'Downstream',
        entity: 'Approval Authority Delegations Export'
      },
      {
        group: 'Downstream',
        entity: 'Export Receipt'
      },
      {
        group: 'Downstream',
        entity: 'Export ERP Receipts'
      },
      {
        group: 'Downstream',
        entity: 'Export Payment'
      },
      {
        group: 'Downstream',
        entity: 'Export Payment Requests'
      }
    ]
  },
  spendVisibility: {
    sap: [
      {
        group: 'spendVisibility',
        entity: 'Spend Visibility Data'
      }
    ]
  },
  core: [
    {
      entity: 'suppliers',
      group: 'core'
    },
    {
      entity: 'remitToAddresses',
      group: 'core'
    },
    {
      entity: 'addresses',
      group: 'core'
    },
    {
      entity: 'purchaseOrders',
      group: 'core'
    },
    {
      entity: 'uoms',
      group: 'core'
    },
    {
      entity: 'accounts',
      group: 'core'
    },
    {
      entity: 'users',
      group: 'core'
    },
    {
      entity: 'commodities',
      group: 'core'
    },
    {
      entity: 'contracts',
      group: 'core'
    },
    {
      entity: 'supplierItems',
      group: 'core'
    },
    {
      entity: 'items',
      group: 'core'
    },
    {
      entity: 'invoiceStatus',
      group: 'core'
    }
  ],
  purchaseOrderApi: [
    {
      entity: 'A_PurchaseOrder',
      group: 'purchaseOrderApi'
    }
  ],
  businessPartnerApi: [
    {
      entity: 'A_Supplier',
      group: 'businessPartnerApi'
    },
    {
      entity: 'A_BusinessPartnerAddress',
      group: 'businessPartnerApi'
    }
  ],
  supplierDataPaginationApi: {
    sap: [
      {
        entity: 'supplier',
        group: 'supplierDataPaginationApi'
      }
    ],
    generic: []
  },
  soapConnectionApi: [
    {
      entity: 'purchaseOrders',
      group: 'soapConnectionApi'
    },
    {
      entity: 'suppliers',
      group: 'soapConnectionApi'
    },
    {
      entity: 'supplierConnections',
      group: 'soapConnectionApi'
    },
    {
      entity: 'supplierInvoices',
      group: 'soapConnectionApi'
    }
  ],
  raasApi: [
    {
      entity: 'CRI_Relish_Spend_Categories',
      group: 'raasApi'
    }
  ],
  restConnectionApi: [
    {
      entity: 'A_PurchaseOrder',
      group: 'purchaseOrderApi'
    }
  ],
  aribaNetworkPurchaseOrdersBuyer: [
    {
      entity: 'PurchaseOrdersView',
      group: 'aribaNetworkPurchaseOrdersBuyer'
    }
  ],
  aribaNetworkInvoiceHeaderDataExtraction: [
    {
      entity: 'invoiceUpdateStatus',
      group: 'aribaNetworkInvoiceHeaderDataExtraction'
    }
  ],
  default: {
    sap: [],
    generic: []
  }
}

const viewsAriba = {
  procurement: {
    sap: [
      {
        displayName: 'Procurement API',
        viewName: 'Requisition_SAP_updatedRange'
      },
      {
        displayName: 'Procurement API',
        viewName: 'Invoice_SAP_updatedRange'
      },
      {
        displayName: 'Procurement API',
        viewName: 'InvoiceReconciliation_SAP_updatedRange'
      },
      {
        displayName: 'Procurement API',
        viewName: 'Receipt_SAP_updatedRange'
      },
      {
        displayName: 'Procurement API',
        viewName: 'ERPOrder_SAP_updatedRange'
      },
      {
        displayName: 'Procurement API',
        viewName: 'DirectOrder_SAP_updatedRange'
      },
      {
        displayName: 'Procurement API',
        viewName: 'CopyOrder_SAP_updatedRange'
      },
      {
        displayName: 'Procurement API',
        viewName: 'Requisition_SAP_createdRange'
      },
      {
        displayName: 'Procurement API',
        viewName: 'Invoice_SAP_createdRange'
      },
      {
        displayName: 'Procurement API',
        viewName: 'InvoiceReconciliation_SAP_createdRange'
      },
      {
        displayName: 'Procurement API',
        viewName: 'Receipt_SAP_createdRange'
      },
      {
        displayName: 'Procurement API',
        viewName: 'ERPOrder_SAP_createdRange'
      },
      {
        displayName: 'Procurement API',
        viewName: 'DirectOrder_SAP_createdRange'
      },
      {
        displayName: 'Procurement API',
        viewName: 'CopyOrder_SAP_createdRange'
      },
      {
        displayName: 'Procurement API',
        viewName: 'Requisition_SAP_updatedRange_v2'
      },
      {
        displayName: 'Procurement API',
        viewName: 'Invoice_SAP_updatedRange_v2'
      },
      {
        displayName: 'Procurement API',
        viewName: 'Requisition_SAP_createdRange_v2'
      },
      {
        displayName: 'Procurement API',
        viewName: 'Invoice_SAP_createdRange_v2'
      },
      {
        displayName: 'Procurement API',
        viewName: 'Contract_SAP_createdRange_v1'
      },
      {
        displayName: 'Procurement API',
        viewName: 'Contract_SAP_updatedRange_v1'
      }
    ],
    generic: [
      {
        viewName: 'Invoice_Generic_updatedRange',
        displayName: 'Procurement API'
      },
      {
        viewName: 'DirectOrder_Generic_createdRange',
        displayName: 'Procurement API'
      },
      {
        viewName: 'CopyOrder_Generic_createdRange',
        displayName: 'Procurement API'
      },
      {
        viewName: 'Requisition_Generic_updatedRange',
        displayName: 'Procurement API'
      },
      {
        viewName: 'Requisition_Generic_createdRange',
        displayName: 'Procurement API'
      },
      {
        viewName: 'Invoice_Generic_createdRange',
        displayName: 'Procurement API'
      },
      {
        viewName: 'InvoiceReconciliation_Generic_createdRange',
        displayName: 'Procurement API'
      },
      {
        viewName: 'Receipt_Generic_createdRange',
        displayName: 'Procurement API'
      },
      {
        viewName: 'ERPOrder_Generic_createdRange',
        displayName: 'Procurement API'
      },
      {
        viewName: 'InvoiceReconciliation_Generic_updatedRange',
        displayName: 'Procurement API'
      },
      {
        viewName: 'Receipt_Generic_updatedRange',
        displayName: 'Procurement API'
      },
      {
        viewName: 'ERPOrder_Generic_updatedRange',
        displayName: 'Procurement API'
      },
      {
        viewName: 'DirectOrder_Generic_updatedRange',
        displayName: 'Procurement API'
      },
      {
        viewName: 'CopyOrder_Generic_updatedRange',
        displayName: 'Procurement API'
      },
      {
        viewName: 'Requisition_Generic_createdRange_v2',
        displayName: 'Procurement API'
      },
      {
        viewName: 'Invoice_Generic_createdRange_v2',
        displayName: 'Procurement API'
      },
      {
        viewName: 'Requisition_Generic_updatedRange_v2',
        displayName: 'Procurement API'
      },
      {
        viewName: 'Invoice_Generic_updatedRange_v2',
        displayName: 'Procurement API'
      },
      {
        viewName: 'Contract_Generic_createdRange_v1',
        displayName: 'Procurement API'
      },
      {
        viewName: 'Contract_Generic_updatedRange_v1',
        displayName: 'Procurement API'
      }
    ]
  },
  analytics: {
    sap: [
      {
        viewName: 'AdvancePaymentFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'UserAbilityFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SupplierFactFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'S4ApprovalFlowFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ProjectFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SourcingProjectFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SourcingRequestFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SPMProjectFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SQMProjectFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'DocumentFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ProjectTaskFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ProjectGroupFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SavingsFormFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SavingsAllocationDetailsFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'BaseConsultingCategoryLineItemFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'CommodityExperienceDataFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'CombinedPriceIndexFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'AdvancePaymentIncludesLineLevelFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ApprovalHistoryFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ApprovalsFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'PendingApprovalFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'BudgetLineFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FinancialBudgetFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'BusinessContactFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ChargeExceptionsFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ERHeaderFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ERLineItemFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ERHeaderViolationFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ERLineViolationFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'AttendeeFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ChargeFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'CatalogReportingEntryFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'CollaborationRequestFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ProposalLineFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractFactFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractLineItemFactFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ForecastedContractSpendFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'GeneralContractWorkspaceFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractAmendmentDetailsFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractWorkspaceFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractRequestFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SalesContractWorkspaceFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SalesContractRequestFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'InternalContractWorkspaceFactFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractClauseFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractItemFactFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractLineItemDocumentFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ItemAttributesFactFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractorProjectFactFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractorEngagementProjectFactFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'OrderConfirmationFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'BaseMarketResearchCategoryLineItemFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'PaymentFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'InvoiceHeaderExceptionFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'InvoiceExceptionsFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'InvLineExceptionFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'InvoiceLineItemFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SSPRejectedInvoiceFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'OneTimeVendorsFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SSPInvoiceLineItemFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SSPPrereconciledInvoiceLineItemFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'RequisitionLineItemFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'RequisitionHeldLineItemFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ReceiptFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'POLineItemFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SSPPOLineItemFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FeedbackRuleFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FinancialsFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'BaseGenericCategoryLineItemFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ServicesProcurementRequestFactFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SRSurveyResponseFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ServiceSheetFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ServicesProcurementWorkspaceFactFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'PODeliveryFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'EventSummaryFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'EventParticipationFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'RfxItemSummaryFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SupplierParticipationFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ScorecardFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SurveyParticipationFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SurveyResponseFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'RequestFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SMProjectFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SupplierRequestProjectFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SupplierRegistrationProjectFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SMSurveyResponseFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SRProjectFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SRProjectTaskFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SRProjectTaskApprovalFlowFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SRProjectSurveyResponseFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'CostCenterDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SupplierDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'UNSPSCDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'CommodityDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SupplierDiversityFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SupplierGreenCertificateFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'TemporaryLaborFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'TimeSheetFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ExpenseSheetFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'UserActivityFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'LocationDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'RegionDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'OrganizationDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ProcurementSystemDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SourceSystemDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'PartDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'UOMDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'AccountDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'UserDataDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'UNSPSC2DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'CompanyCodeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'AssetDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'PCardDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ERPCommodityDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'CompanySiteDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'RevenueAmountRangeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'AmountRangeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'PercentRangeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'TimeRangeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'NumberLinesRangeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'TimeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'CompanyDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ProcurementUnitDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ProductDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'AccountingProjectDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'AccountingRegionDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SubAccountDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'StatisticsCodeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'InternalOrderDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'NetworkDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ActivityNumberDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractorDimDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ACMProductDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'WorkspaceFolderDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'CommodityEscalationDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FeedbackRequestDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'TaxCodeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'RemittanceLocationDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ServiceCategoryDefinitionDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ProjectInfoDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'VersionedProjectInfoDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'EventTypeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ProcessDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ProjectTaskNameDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SavingsFormInfoDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'CityDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'VendorDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ExpenseTypeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ViolationPolicyTypeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'LineViolationDetailsDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'BenchmarkPeriodTypeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'EventDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SurveyQuestionDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SurveyDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SMProjectInfoDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SMSurveyTemplateQuestionDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'InvoiceExceptionTypeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension1DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension2DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension3DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension4DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension5DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension6DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension7DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension8DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension9DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension10DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension11DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension12DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension13DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension14DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ScorecardKPIDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ScorecardDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SRProjectInfoDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'JobTypeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractorProjectCodeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractorProjectTaskDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SSPPreInvLineItemFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'TestContracts',
        displayName: 'Analytic API'
      },
      {
        viewName: 'TestContracts2',
        displayName: 'Analytic API'
      },
      {
        viewName: 'TestContracts3',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SLContractReportTest',
        displayName: 'Analytic API'
      }
    ],
    generic: [
      {
        viewName: 'AdvancePaymentFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'UserAbilityFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SupplierFactFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'S4ApprovalFlowFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ProjectFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SourcingProjectFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SourcingRequestFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SPMProjectFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SQMProjectFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'DocumentFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ProjectTaskFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ProjectGroupFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SavingsFormFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SavingsAllocationDetailsFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'BaseConsultingCategoryLineItemFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'CommodityExperienceDataFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'CombinedPriceIndexFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'AdvancePaymentIncludesLineLevelFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ApprovalHistoryFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ApprovalsFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'PendingApprovalFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'BudgetLineFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FinancialBudgetFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'BusinessContactFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ChargeExceptionsFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ERHeaderFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ERLineItemFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ERHeaderViolationFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ERLineViolationFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'AttendeeFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ChargeFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'CatalogReportingEntryFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'CollaborationRequestFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ProposalLineFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractFactFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractLineItemFactFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ForecastedContractSpendFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'GeneralContractWorkspaceFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractAmendmentDetailsFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractWorkspaceFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractRequestFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SalesContractWorkspaceFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SalesContractRequestFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'InternalContractWorkspaceFactFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractClauseFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractItemFactFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractLineItemDocumentFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ItemAttributesFactFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractorProjectFactFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractorEngagementProjectFactFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'OrderConfirmationFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'BaseMarketResearchCategoryLineItemFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'PaymentFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'InvoiceHeaderExceptionFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'InvoiceExceptionsFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'InvLineExceptionFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'InvoiceLineItemFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SSPRejectedInvoiceFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'OneTimeVendorsFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SSPInvoiceLineItemFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SSPPrereconciledInvoiceLineItemFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'RequisitionLineItemFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'RequisitionHeldLineItemFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ReceiptFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'POLineItemFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SSPPOLineItemFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FeedbackRuleFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FinancialsFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'BaseGenericCategoryLineItemFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ServicesProcurementRequestFactFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SRSurveyResponseFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ServiceSheetFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ServicesProcurementWorkspaceFactFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'PODeliveryFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'EventSummaryFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'EventParticipationFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'RfxItemSummaryFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SupplierParticipationFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ScorecardFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SurveyParticipationFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SurveyResponseFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'RequestFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SMProjectFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SupplierRequestProjectFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SupplierRegistrationProjectFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SMSurveyResponseFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SRProjectFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SRProjectTaskFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SRProjectTaskApprovalFlowFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SRProjectSurveyResponseFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'CostCenterDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SupplierDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'UNSPSCDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'CommodityDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SupplierDiversityFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SupplierGreenCertificateFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'TemporaryLaborFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'TimeSheetFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ExpenseSheetFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'UserActivityFactSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'LocationDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'RegionDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'OrganizationDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ProcurementSystemDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SourceSystemDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'PartDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'UOMDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'AccountDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'UserDataDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'UNSPSC2DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'CompanyCodeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'AssetDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'PCardDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ERPCommodityDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'CompanySiteDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'RevenueAmountRangeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'AmountRangeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'PercentRangeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'TimeRangeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'NumberLinesRangeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'TimeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'CompanyDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ProcurementUnitDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ProductDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'AccountingProjectDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'AccountingRegionDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SubAccountDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'StatisticsCodeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'InternalOrderDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'NetworkDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ActivityNumberDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractorDimDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ACMProductDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'WorkspaceFolderDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'CommodityEscalationDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FeedbackRequestDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'TaxCodeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'RemittanceLocationDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ServiceCategoryDefinitionDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ProjectInfoDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'VersionedProjectInfoDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'EventTypeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ProcessDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ProjectTaskNameDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SavingsFormInfoDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'CityDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'VendorDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ExpenseTypeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ViolationPolicyTypeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'LineViolationDetailsDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'BenchmarkPeriodTypeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'EventDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SurveyQuestionDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SurveyDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SMProjectInfoDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SMSurveyTemplateQuestionDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'InvoiceExceptionTypeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension1DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension2DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension3DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension4DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension5DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension6DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension7DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension8DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension9DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension10DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension11DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension12DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension13DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'FlexDimension14DimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ScorecardKPIDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ScorecardDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SRProjectInfoDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'JobTypeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractorProjectCodeDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'ContractorProjectTaskDimSystemView',
        displayName: 'Analytic API'
      },
      {
        viewName: 'SSPPreInvLineItemFactSystemView',
        displayName: 'Analytic API'
      }
    ]
  },
  sourcing: {
    sap: [
      {
        displayName: 'Sourcing API',
        viewName: 'UserSourcingSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'OrganizationSourcingSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'TaskSourcingSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'DocumentTaskSourcingSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'AuditEntrySourcingSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ProjectAuditInfoSourcingSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SourcingProjectSourcingSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'RFXDocumentSourcingSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'AdvancePaymentFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'UserAbilityFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SupplierFactFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'S4ApprovalFlowFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ProjectFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SourcingProjectFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SourcingRequestFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SPMProjectFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SQMProjectFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'DocumentFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ProjectTaskFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ProjectGroupFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SavingsFormFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SavingsAllocationDetailsFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'BaseConsultingCategoryLineItemFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'CommodityExperienceDataFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'CombinedPriceIndexFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'CustomBenchmarkingFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'AdvancePaymentIncludesLineLevelFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ApprovalHistoryFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ApprovalsFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'PendingApprovalFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'BudgetLineFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'FinancialBudgetFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'BusinessContactFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ChargeExceptionsFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ERHeaderFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ERLineItemFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ERHeaderViolationFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ERLineViolationFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'AttendeeFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ChargeFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'CatalogReportingEntryFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'CollaborationRequestFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ProposalLineFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'CustomFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ContractsDFormFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ContractsDFormDetailFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'EFormFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ConsultingCategoryLineItemFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ContractFactFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ContractLineItemFactFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ForecastedContractSpendFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'GeneralContractWorkspaceFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ContractAmendmentDetailsFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ContractWorkspaceFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ContractRequestFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SalesContractWorkspaceFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SalesContractRequestFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'InternalContractWorkspaceFactFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ContractClauseFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ContractItemFactFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ContractLineItemDocumentFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ItemAttributesFactFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ContractorProjectFactFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ContractorEngagementProjectFactFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'OrderConfirmationFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'GenericCategoryLineItemFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'BaseMarketResearchCategoryLineItemFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'MarketResearchCategoryLineItemFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'EFormDetailFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'PaymentFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'InvoiceHeaderExceptionFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'InvoiceExceptionsFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'InvLineExceptionFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'InvoiceLineItemFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SSPRejectedInvoiceFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'OneTimeVendorsFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SSPInvoiceLineItemFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SSPPrereconciledInvoiceLineItemFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'RequisitionLineItemFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'RequisitionHeldLineItemFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ReceiptFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'POLineItemFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SSPPOLineItemFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'FeedbackRuleFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'FinancialsFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'BaseGenericCategoryLineItemFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ServicesProcurementRequestFactFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SRSurveyResponseFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ServiceSheetFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ServicesProcurementWorkspaceFactFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'PODeliveryFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'EventSummaryFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'EventParticipationFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'RfxItemSummaryFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SupplierParticipationFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ScorecardFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SurveyParticipationFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SurveyResponseFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'RequestFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SMProjectFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SupplierRequestProjectFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SupplierRegistrationProjectFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SMSurveyResponseFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SRProjectFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SRProjectTaskFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SRProjectTaskApprovalFlowFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SRProjectSurveyResponseFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'CostCenterDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SupplierDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'UNSPSCDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'CommodityDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SupplierDiversityFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SupplierGreenCertificateFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'TemporaryLaborFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'TimeSheetFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ExpenseSheetFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'DerivedTemporaryLaborCategoryLineItemFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'UserActivityFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'LocationDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'RegionDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'OrganizationDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ProcurementSystemDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SourceSystemDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ContractDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'PartDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'UOMDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'AccountDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'UserDataDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'UNSPSC2DimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'CompanyCodeDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'AssetDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'PCardDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ERPCommodityDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'CompanySiteDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'RevenueAmountRangeDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'AmountRangeDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'PercentRangeDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'TimeRangeDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'NumberLinesRangeDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'TimeDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'CompanyDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ProcurementUnitDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ProductDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'AccountingProjectDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'AccountingRegionDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SubAccountDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'StatisticsCodeDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'InternalOrderDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'NetworkDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ActivityNumberDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ContractorDimDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ACMProductDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'WorkspaceFolderDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'CommodityEscalationDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'FeedbackRequestDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'TaxCodeDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'RemittanceLocationDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ServiceCategoryDefinitionDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ProjectInfoDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'VersionedProjectInfoDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'EventTypeDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ProcessDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ProjectTaskNameDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SavingsFormInfoDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'CityDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'VendorDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ExpenseTypeDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ViolationPolicyTypeDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'LineViolationDetailsDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'BenchmarkPeriodTypeDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'EventDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SurveyQuestionDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SurveyDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SMProjectInfoDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SMSurveyTemplateQuestionDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'InvoiceExceptionTypeDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'FlexDimension1DimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'FlexDimension2DimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'FlexDimension3DimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'FlexDimension4DimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'FlexDimension5DimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'FlexDimension6DimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'FlexDimension7DimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'FlexDimension8DimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'FlexDimension9DimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'FlexDimension10DimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'FlexDimension11DimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'FlexDimension12DimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'FlexDimension13DimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'FlexDimension14DimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ScorecardKPIDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ScorecardDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SRProjectInfoDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'JobTypeDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ContractorProjectCodeDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'ContractorProjectTaskDimSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SSPPreInvLineItemFactSystemView'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'TestContracts'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'TestContracts2'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'TestContracts3'
      },
      {
        displayName: 'Sourcing API',
        viewName: 'SLContractReportTest'
      }
    ],
    generic: [
      {
        viewName: 'UserSourcingSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'OrganizationSourcingSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'TaskSourcingSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'DocumentTaskSourcingSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'AuditEntrySourcingSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ProjectAuditInfoSourcingSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SourcingProjectSourcingSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'RFXDocumentSourcingSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'AdvancePaymentFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'UserAbilityFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SupplierFactFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'S4ApprovalFlowFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ProjectFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SourcingProjectFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SourcingRequestFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SPMProjectFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SQMProjectFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'DocumentFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ProjectTaskFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ProjectGroupFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SavingsFormFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SavingsAllocationDetailsFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'BaseConsultingCategoryLineItemFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'CommodityExperienceDataFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'CombinedPriceIndexFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'CustomBenchmarkingFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'AdvancePaymentIncludesLineLevelFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ApprovalHistoryFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ApprovalsFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'PendingApprovalFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'BudgetLineFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'FinancialBudgetFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'BusinessContactFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ChargeExceptionsFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ERHeaderFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ERLineItemFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ERHeaderViolationFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ERLineViolationFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'AttendeeFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ChargeFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'CatalogReportingEntryFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'CollaborationRequestFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ProposalLineFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'CustomFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ContractsDFormFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ContractsDFormDetailFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'EFormFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ConsultingCategoryLineItemFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ContractFactFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ContractLineItemFactFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ForecastedContractSpendFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'GeneralContractWorkspaceFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ContractAmendmentDetailsFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ContractWorkspaceFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ContractRequestFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SalesContractWorkspaceFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SalesContractRequestFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'InternalContractWorkspaceFactFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ContractClauseFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ContractItemFactFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ContractLineItemDocumentFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ItemAttributesFactFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ContractorProjectFactFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ContractorEngagementProjectFactFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'OrderConfirmationFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'GenericCategoryLineItemFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'BaseMarketResearchCategoryLineItemFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'MarketResearchCategoryLineItemFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'EFormDetailFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'PaymentFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'InvoiceHeaderExceptionFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'InvoiceExceptionsFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'InvLineExceptionFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'InvoiceLineItemFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SSPRejectedInvoiceFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'OneTimeVendorsFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SSPInvoiceLineItemFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SSPPrereconciledInvoiceLineItemFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'RequisitionLineItemFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'RequisitionHeldLineItemFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ReceiptFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'POLineItemFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SSPPOLineItemFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'FeedbackRuleFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'FinancialsFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'BaseGenericCategoryLineItemFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ServicesProcurementRequestFactFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SRSurveyResponseFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ServiceSheetFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ServicesProcurementWorkspaceFactFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'PODeliveryFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'EventSummaryFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'EventParticipationFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'RfxItemSummaryFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SupplierParticipationFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ScorecardFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SurveyParticipationFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SurveyResponseFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'RequestFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SMProjectFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SupplierRequestProjectFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SupplierRegistrationProjectFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SMSurveyResponseFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SRProjectFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SRProjectTaskFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SRProjectTaskApprovalFlowFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SRProjectSurveyResponseFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'CostCenterDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SupplierDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'UNSPSCDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'CommodityDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SupplierDiversityFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SupplierGreenCertificateFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'TemporaryLaborFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'TimeSheetFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ExpenseSheetFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'DerivedTemporaryLaborCategoryLineItemFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'UserActivityFactSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'LocationDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'RegionDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'OrganizationDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ProcurementSystemDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SourceSystemDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ContractDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'PartDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'UOMDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'AccountDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'UserDataDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'UNSPSC2DimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'CompanyCodeDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'AssetDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'PCardDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ERPCommodityDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'CompanySiteDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'RevenueAmountRangeDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'AmountRangeDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'PercentRangeDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'TimeRangeDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'NumberLinesRangeDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'TimeDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'CompanyDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ProcurementUnitDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ProductDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'AccountingProjectDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'AccountingRegionDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SubAccountDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'StatisticsCodeDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'InternalOrderDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'NetworkDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ActivityNumberDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ContractorDimDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ACMProductDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'WorkspaceFolderDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'CommodityEscalationDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'FeedbackRequestDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'TaxCodeDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'RemittanceLocationDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ServiceCategoryDefinitionDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ProjectInfoDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'VersionedProjectInfoDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'EventTypeDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ProcessDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ProjectTaskNameDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SavingsFormInfoDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'CityDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'VendorDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ExpenseTypeDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ViolationPolicyTypeDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'LineViolationDetailsDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'BenchmarkPeriodTypeDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'EventDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SurveyQuestionDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SurveyDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SMProjectInfoDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SMSurveyTemplateQuestionDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'InvoiceExceptionTypeDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'FlexDimension1DimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'FlexDimension2DimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'FlexDimension3DimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'FlexDimension4DimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'FlexDimension5DimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'FlexDimension6DimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'FlexDimension7DimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'FlexDimension8DimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'FlexDimension9DimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'FlexDimension10DimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'FlexDimension11DimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'FlexDimension12DimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'FlexDimension13DimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'FlexDimension14DimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ScorecardKPIDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ScorecardDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SRProjectInfoDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'JobTypeDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ContractorProjectCodeDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'ContractorProjectTaskDimSystemView',
        displayName: 'Sourcing API'
      },
      {
        viewName: 'SSPPreInvLineItemFactSystemView',
        displayName: 'Sourcing API'
      }
    ]
  },
  upstream: {
    sap: [
      {
        displayName: 'Upstream',
        viewName: 'Export Units Of Measure (CSV)'
      },
      {
        displayName: 'Upstream',
        viewName: 'Export Supplier Users and Organizations'
      },
      {
        displayName: 'Upstream',
        viewName: 'Export Supplier Users and Organizations (CSV)'
      },
      {
        displayName: 'Upstream',
        viewName: 'Export Supplier Users'
      },
      {
        displayName: 'Upstream',
        viewName: 'Export Supplier Users (CSV)'
      },
      {
        displayName: 'Upstream',
        viewName: 'Export Supplier Profiles'
      },
      {
        displayName: 'Upstream',
        viewName: 'Export Supplier Organizations (CSV)'
      },
      {
        displayName: 'Upstream',
        viewName: 'Export Region Codes (CSV)'
      },
      {
        displayName: 'Upstream',
        viewName: 'Export Organization with vendor keys (csv)'
      },
      {
        displayName: 'Upstream',
        viewName: 'Export Organizations (to csv file)'
      },
      {
        displayName: 'Upstream',
        viewName: 'Export Organization IDs (zipped CSV)'
      },
      {
        displayName: 'Upstream',
        viewName: 'Export Locales (CSV)'
      },
      {
        displayName: 'Upstream',
        viewName: 'Export Languages (CSV)'
      },
      {
        displayName: 'Upstream',
        viewName: 'Export Enterprise Users CSV'
      },
      {
        displayName: 'Upstream',
        viewName: 'Export Enterprise Users'
      },
      {
        displayName: 'Upstream',
        viewName: 'Export Enterprise Organizations Hierarchy (CSV)'
      },
      {
        displayName: 'Upstream',
        viewName: 'Export Deactivated Users (CSV)'
      },
      {
        displayName: 'Upstream',
        viewName: 'Export Currencies (CSV)'
      },
      {
        displayName: 'Upstream',
        viewName: 'Export Countries (CSV)'
      },
      {
        displayName: 'Upstream',
        viewName: 'Export Contract Header Data (ZIP)'
      },
      {
        displayName: 'Upstream',
        viewName: 'Export Addresses (CSV)'
      }
    ]
  },
  downstream: {
    sap: [
      {
        displayName: 'Downstream',
        viewName: 'User Consolidated Export'
      },
      {
        displayName: 'Downstream',
        viewName: 'Supplier Location Consolidated Export'
      },
      {
        displayName: 'Downstream',
        viewName: 'Supplier Consolidated Export'
      },
      {
        displayName: 'Downstream',
        viewName: 'Remittance Location Consolidated Export'
      },
      {
        displayName: 'Downstream',
        viewName: 'Payment Terms Consolidated Export'
      },
      {
        displayName: 'Downstream',
        viewName: 'Group Mapping Consolidated Export'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Suppliers v2'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Supplier Supplement Data'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Suppliers'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Supplier Organizations'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Supplier Location Remittance Information'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Supplier Location Contacts'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Supplier Bank Payment Locations'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Receipt Status'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Receipts'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Purchase Orders'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Purchase Order Close Status'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Purchase Order'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Preferred Supplier Mappings'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export PCard Limits'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export PCard Accounting Association'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Payment Requests with Advance Payments'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Kits'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Invoices'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Financial Extract'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Enterprise Users Browser Info'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Enterprise Users'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Deactivated Enterprise Users'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Contractors'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Charge Payment Requests'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Change Purchase Order'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Cancel Purchase Order'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Addresses'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Cancel Advance Payments'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Advance Payments'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Accrual Transactions'
      },
      {
        displayName: 'Downstream',
        viewName: 'Approval Authority Delegations Export'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Receipt'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export ERP Receipts'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Payment'
      },
      {
        displayName: 'Downstream',
        viewName: 'Export Payment Requests'
      }
    ]
  },
  spendVisibility: {
    sap: [
      {
        displayName: 'Spend Visibility',
        viewName: 'Spend Visibility Data'
      }
    ]
  },
  default: {
    sap: [],
    generic: []
  }
}

const invoiceViewsAriba = {
  procurement: [
    {
      displayName: 'Procurement API',
      viewName: 'Relish_InvoiceReconciliationStatusUpdate_v1'
    },
    {
      displayName: 'Procurement API',
      viewName: 'Relish_InvoiceStatusUpdate_v1'
    }
  ],
  analytics: [
    {
      viewName: 'AdvancePaymentFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'UserAbilityFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SupplierFactFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'S4ApprovalFlowFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ProjectFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SourcingProjectFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SourcingRequestFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SPMProjectFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SQMProjectFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'DocumentFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ProjectTaskFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ProjectGroupFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SavingsFormFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SavingsAllocationDetailsFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'BaseConsultingCategoryLineItemFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'CommodityExperienceDataFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'CombinedPriceIndexFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'AdvancePaymentIncludesLineLevelFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ApprovalHistoryFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ApprovalsFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'PendingApprovalFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'BudgetLineFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'FinancialBudgetFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'BusinessContactFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ChargeExceptionsFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ERHeaderFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ERLineItemFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ERHeaderViolationFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ERLineViolationFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'AttendeeFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ChargeFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'CatalogReportingEntryFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'CollaborationRequestFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ProposalLineFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ContractFactFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ContractLineItemFactFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ForecastedContractSpendFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'GeneralContractWorkspaceFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ContractAmendmentDetailsFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ContractWorkspaceFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ContractRequestFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SalesContractWorkspaceFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SalesContractRequestFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'InternalContractWorkspaceFactFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ContractClauseFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ContractItemFactFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ContractLineItemDocumentFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ItemAttributesFactFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ContractorProjectFactFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ContractorEngagementProjectFactFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'OrderConfirmationFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'BaseMarketResearchCategoryLineItemFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'PaymentFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'InvoiceHeaderExceptionFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'InvoiceExceptionsFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'InvLineExceptionFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'InvoiceLineItemFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SSPRejectedInvoiceFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'OneTimeVendorsFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SSPInvoiceLineItemFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SSPPrereconciledInvoiceLineItemFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'RequisitionLineItemFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'RequisitionHeldLineItemFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ReceiptFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'POLineItemFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SSPPOLineItemFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'FeedbackRuleFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'FinancialsFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'BaseGenericCategoryLineItemFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ServicesProcurementRequestFactFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SRSurveyResponseFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ServiceSheetFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ServicesProcurementWorkspaceFactFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'PODeliveryFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'EventSummaryFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'EventParticipationFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'RfxItemSummaryFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SupplierParticipationFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ScorecardFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SurveyParticipationFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SurveyResponseFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'RequestFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SMProjectFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SupplierRequestProjectFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SupplierRegistrationProjectFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SMSurveyResponseFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SRProjectFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SRProjectTaskFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SRProjectTaskApprovalFlowFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SRProjectSurveyResponseFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'CostCenterDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SupplierDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'UNSPSCDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'CommodityDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SupplierDiversityFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SupplierGreenCertificateFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'TemporaryLaborFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'TimeSheetFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ExpenseSheetFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'UserActivityFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'LocationDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'RegionDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'OrganizationDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ProcurementSystemDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SourceSystemDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ContractDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'PartDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'UOMDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'AccountDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'UserDataDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'UNSPSC2DimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'CompanyCodeDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'AssetDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'PCardDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ERPCommodityDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'CompanySiteDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'RevenueAmountRangeDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'AmountRangeDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'PercentRangeDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'TimeRangeDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'NumberLinesRangeDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'TimeDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'CompanyDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ProcurementUnitDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ProductDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'AccountingProjectDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'AccountingRegionDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SubAccountDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'StatisticsCodeDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'InternalOrderDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'NetworkDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ActivityNumberDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ContractorDimDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ACMProductDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'WorkspaceFolderDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'CommodityEscalationDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'FeedbackRequestDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'TaxCodeDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'RemittanceLocationDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ServiceCategoryDefinitionDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ProjectInfoDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'VersionedProjectInfoDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'EventTypeDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ProcessDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ProjectTaskNameDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SavingsFormInfoDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'CityDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'VendorDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ExpenseTypeDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ViolationPolicyTypeDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'LineViolationDetailsDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'BenchmarkPeriodTypeDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'EventDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SurveyQuestionDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SurveyDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SMProjectInfoDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SMSurveyTemplateQuestionDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'InvoiceExceptionTypeDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'FlexDimension1DimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'FlexDimension2DimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'FlexDimension3DimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'FlexDimension4DimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'FlexDimension5DimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'FlexDimension6DimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'FlexDimension7DimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'FlexDimension8DimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'FlexDimension9DimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'FlexDimension10DimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'FlexDimension11DimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'FlexDimension12DimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'FlexDimension13DimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'FlexDimension14DimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ScorecardKPIDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ScorecardDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SRProjectInfoDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'JobTypeDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ContractorProjectCodeDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'ContractorProjectTaskDimSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SSPPreInvLineItemFactSystemView',
      displayName: 'Analytic API'
    },
    {
      viewName: 'TestContracts',
      displayName: 'Analytic API'
    },
    {
      viewName: 'TestContracts2',
      displayName: 'Analytic API'
    },
    {
      viewName: 'TestContracts3',
      displayName: 'Analytic API'
    },
    {
      viewName: 'SLContractReportTest',
      displayName: 'Analytic API'
    }
  ],
  sourcing: [
    {
      displayName: 'Sourcing API',
      viewName: 'UserSourcingSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'OrganizationSourcingSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'TaskSourcingSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'DocumentTaskSourcingSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'AuditEntrySourcingSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ProjectAuditInfoSourcingSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SourcingProjectSourcingSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'RFXDocumentSourcingSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'AdvancePaymentFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'UserAbilityFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SupplierFactFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'S4ApprovalFlowFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ProjectFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SourcingProjectFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SourcingRequestFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SPMProjectFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SQMProjectFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'DocumentFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ProjectTaskFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ProjectGroupFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SavingsFormFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SavingsAllocationDetailsFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'BaseConsultingCategoryLineItemFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'CommodityExperienceDataFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'CombinedPriceIndexFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'CustomBenchmarkingFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'AdvancePaymentIncludesLineLevelFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ApprovalHistoryFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ApprovalsFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'PendingApprovalFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'BudgetLineFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'FinancialBudgetFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'BusinessContactFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ChargeExceptionsFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ERHeaderFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ERLineItemFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ERHeaderViolationFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ERLineViolationFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'AttendeeFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ChargeFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'CatalogReportingEntryFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'CollaborationRequestFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ProposalLineFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'CustomFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ContractsDFormFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ContractsDFormDetailFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'EFormFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ConsultingCategoryLineItemFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ContractFactFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ContractLineItemFactFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ForecastedContractSpendFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'GeneralContractWorkspaceFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ContractAmendmentDetailsFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ContractWorkspaceFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ContractRequestFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SalesContractWorkspaceFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SalesContractRequestFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'InternalContractWorkspaceFactFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ContractClauseFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ContractItemFactFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ContractLineItemDocumentFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ItemAttributesFactFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ContractorProjectFactFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ContractorEngagementProjectFactFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'OrderConfirmationFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'GenericCategoryLineItemFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'BaseMarketResearchCategoryLineItemFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'MarketResearchCategoryLineItemFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'EFormDetailFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'PaymentFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'InvoiceHeaderExceptionFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'InvoiceExceptionsFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'InvLineExceptionFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'InvoiceLineItemFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SSPRejectedInvoiceFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'OneTimeVendorsFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SSPInvoiceLineItemFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SSPPrereconciledInvoiceLineItemFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'RequisitionLineItemFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'RequisitionHeldLineItemFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ReceiptFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'POLineItemFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SSPPOLineItemFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'FeedbackRuleFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'FinancialsFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'BaseGenericCategoryLineItemFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ServicesProcurementRequestFactFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SRSurveyResponseFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ServiceSheetFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ServicesProcurementWorkspaceFactFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'PODeliveryFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'EventSummaryFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'EventParticipationFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'RfxItemSummaryFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SupplierParticipationFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ScorecardFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SurveyParticipationFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SurveyResponseFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'RequestFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SMProjectFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SupplierRequestProjectFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SupplierRegistrationProjectFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SMSurveyResponseFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SRProjectFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SRProjectTaskFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SRProjectTaskApprovalFlowFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SRProjectSurveyResponseFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'CostCenterDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SupplierDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'UNSPSCDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'CommodityDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SupplierDiversityFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SupplierGreenCertificateFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'TemporaryLaborFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'TimeSheetFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ExpenseSheetFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'DerivedTemporaryLaborCategoryLineItemFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'UserActivityFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'LocationDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'RegionDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'OrganizationDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ProcurementSystemDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SourceSystemDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ContractDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'PartDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'UOMDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'AccountDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'UserDataDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'UNSPSC2DimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'CompanyCodeDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'AssetDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'PCardDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ERPCommodityDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'CompanySiteDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'RevenueAmountRangeDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'AmountRangeDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'PercentRangeDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'TimeRangeDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'NumberLinesRangeDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'TimeDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'CompanyDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ProcurementUnitDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ProductDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'AccountingProjectDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'AccountingRegionDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SubAccountDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'StatisticsCodeDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'InternalOrderDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'NetworkDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ActivityNumberDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ContractorDimDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ACMProductDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'WorkspaceFolderDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'CommodityEscalationDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'FeedbackRequestDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'TaxCodeDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'RemittanceLocationDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ServiceCategoryDefinitionDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ProjectInfoDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'VersionedProjectInfoDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'EventTypeDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ProcessDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ProjectTaskNameDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SavingsFormInfoDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'CityDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'VendorDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ExpenseTypeDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ViolationPolicyTypeDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'LineViolationDetailsDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'BenchmarkPeriodTypeDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'EventDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SurveyQuestionDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SurveyDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SMProjectInfoDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SMSurveyTemplateQuestionDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'InvoiceExceptionTypeDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'FlexDimension1DimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'FlexDimension2DimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'FlexDimension3DimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'FlexDimension4DimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'FlexDimension5DimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'FlexDimension6DimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'FlexDimension7DimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'FlexDimension8DimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'FlexDimension9DimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'FlexDimension10DimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'FlexDimension11DimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'FlexDimension12DimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'FlexDimension13DimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'FlexDimension14DimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ScorecardKPIDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ScorecardDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SRProjectInfoDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'JobTypeDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ContractorProjectCodeDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'ContractorProjectTaskDimSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SSPPreInvLineItemFactSystemView'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'TestContracts'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'TestContracts2'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'TestContracts3'
    },
    {
      displayName: 'Sourcing API',
      viewName: 'SLContractReportTest'
    }
  ],
  upstream: [
    {
      displayName: 'Upstream',
      viewName: 'Export Units Of Measure (CSV)'
    },
    {
      displayName: 'Upstream',
      viewName: 'Export Supplier Users and Organizations'
    },
    {
      displayName: 'Upstream',
      viewName: 'Export Supplier Users and Organizations (CSV)'
    },
    {
      displayName: 'Upstream',
      viewName: 'Export Supplier Users'
    },
    {
      displayName: 'Upstream',
      viewName: 'Export Supplier Users (CSV)'
    },
    {
      displayName: 'Upstream',
      viewName: 'Export Supplier Profiles'
    },
    {
      displayName: 'Upstream',
      viewName: 'Export Supplier Organizations (CSV)'
    },
    {
      displayName: 'Upstream',
      viewName: 'Export Region Codes (CSV)'
    },
    {
      displayName: 'Upstream',
      viewName: 'Export Organization with vendor keys (csv)'
    },
    {
      displayName: 'Upstream',
      viewName: 'Export Organizations (to csv file)'
    },
    {
      displayName: 'Upstream',
      viewName: 'Export Organization IDs (zipped CSV)'
    },
    {
      displayName: 'Upstream',
      viewName: 'Export Locales (CSV)'
    },
    {
      displayName: 'Upstream',
      viewName: 'Export Languages (CSV)'
    },
    {
      displayName: 'Upstream',
      viewName: 'Export Enterprise Users CSV'
    },
    {
      displayName: 'Upstream',
      viewName: 'Export Enterprise Users'
    },
    {
      displayName: 'Upstream',
      viewName: 'Export Enterprise Organizations Hierarchy (CSV)'
    },
    {
      displayName: 'Upstream',
      viewName: 'Export Deactivated Users (CSV)'
    },
    {
      displayName: 'Upstream',
      viewName: 'Export Currencies (CSV)'
    },
    {
      displayName: 'Upstream',
      viewName: 'Export Countries (CSV)'
    },
    {
      displayName: 'Upstream',
      viewName: 'Export Contract Header Data (ZIP)'
    },
    {
      displayName: 'Upstream',
      viewName: 'Export Addresses (CSV)'
    }
  ],
  spendVisibility: [{ displayName: 'Spend Visibility', viewName: 'Spend Visibility Data' }],
  default: [],
  downstream: [
    {
      displayName: 'Downstream',
      viewName: 'Supplier Location Consolidated Export'
    },
    {
      displayName: 'Downstream',
      viewName: 'Remittance Location Consolidated Export'
    },
    { displayName: 'Downstream', viewName: 'Export Purchase Orders' }
  ],
  sourcingMasterData: [{ displayName: 'Upstream', viewName: 'Organizations' }],
  procurementMasterData: [
    { displayName: 'Downstream', viewName: 'Suppliers' },
    { displayName: 'Downstream', viewName: 'Groups' },
    { displayName: 'Downstream', viewName: 'Addresses' },
    { displayName: 'Downstream', viewName: 'Uoms' },
    { displayName: 'Downstream', viewName: 'Users' }
  ]
}

const dayOfWeek = [
  { key: 'Mon', label: 'Monday' },
  { key: 'Tue', label: 'Tuesday' },
  { key: 'Wed', label: 'Wednesday' },
  { key: 'Thu', label: 'Thursday' },
  { key: 'Fri', label: 'Friday' },
  { key: 'Sat', label: 'Saturday' },
  { key: 'Sun', label: 'Sunday' }
]

const dayOfWeekDictionary = {
  Mon: 'Monday',
  Tue: 'Tuesday',
  Wed: 'Wednesday',
  Thu: 'Thursday',
  Fri: 'Friday',
  Sat: 'Saturday',
  Sun: 'Sunday'
}

const weeksOfYear = [
  { key: '1', label: '1' },
  { key: '2', label: '2' },
  { key: '3', label: '3' },
  { key: '4', label: '4' },
  { key: '5', label: '5' },
  { key: '6', label: '6' },
  { key: '7', label: '7' },
  { key: '8', label: '8' },
  { key: '9', label: '9' },
  { key: '10', label: '10' },
  { key: '11', label: '11' },
  { key: '12', label: '12' },
  { key: '13', label: '13' },
  { key: '14', label: '14' },
  { key: '15', label: '15' },
  { key: '16', label: '16' },
  { key: '17', label: '17' },
  { key: '18', label: '18' },
  { key: '19', label: '19' },
  { key: '20', label: '20' },
  { key: '21', label: '21' },
  { key: '22', label: '22' },
  { key: '23', label: '23' },
  { key: '24', label: '24' },
  { key: '25', label: '25' },
  { key: '26', label: '26' },
  { key: '27', label: '27' },
  { key: '28', label: '28' },
  { key: '29', label: '29' },
  { key: '30', label: '30' },
  { key: '31', label: '31' },
  { key: '32', label: '32' },
  { key: '33', label: '33' },
  { key: '34', label: '34' },
  { key: '35', label: '35' },
  { key: '36', label: '36' },
  { key: '37', label: '37' },
  { key: '38', label: '38' },
  { key: '39', label: '39' },
  { key: '40', label: '40' },
  { key: '41', label: '41' },
  { key: '42', label: '42' },
  { key: '43', label: '43' },
  { key: '44', label: '44' },
  { key: '45', label: '45' },
  { key: '46', label: '46' },
  { key: '47', label: '47' },
  { key: '48', label: '48' },
  { key: '49', label: '49' },
  { key: '50', label: '50' },
  { key: '51', label: '51' },
  { key: '52', label: '52' },
  { key: '53', label: '53' }
]

const dayOfMonth = [
  { key: '1', label: '1' },
  { key: '2', label: '2' },
  { key: '3', label: '3' },
  { key: '4', label: '4' },
  { key: '5', label: '5' },
  { key: '6', label: '6' },
  { key: '7', label: '7' },
  { key: '8', label: '8' },
  { key: '9', label: '9' },
  { key: '10', label: '10' },
  { key: '11', label: '11' },
  { key: '12', label: '12' },
  { key: '13', label: '13' },
  { key: '14', label: '14' },
  { key: '15', label: '15' },
  { key: '16', label: '16' },
  { key: '17', label: '17' },
  { key: '18', label: '18' },
  { key: '19', label: '19' },
  { key: '20', label: '20' },
  { key: '21', label: '21' },
  { key: '22', label: '22' },
  { key: '23', label: '23' },
  { key: '24', label: '24' },
  { key: '25', label: '25' },
  { key: '26', label: '26' },
  { key: '27', label: '27' },
  { key: '28', label: '28' },
  { key: '29', label: '29' },
  { key: '30', label: '30' },
  { key: '31', label: '31' }
]

const hoursOfDay = [
  { key: '1', label: '1' },
  { key: '2', label: '2' },
  { key: '3', label: '3' },
  { key: '4', label: '4' },
  { key: '5', label: '5' },
  { key: '6', label: '6' },
  { key: '7', label: '7' },
  { key: '8', label: '8' },
  { key: '9', label: '9' },
  { key: '10', label: '10' },
  { key: '11', label: '11' },
  { key: '12', label: '12' },
  { key: '13', label: '13' },
  { key: '14', label: '14' },
  { key: '15', label: '15' },
  { key: '16', label: '16' },
  { key: '17', label: '17' },
  { key: '18', label: '18' },
  { key: '19', label: '19' },
  { key: '20', label: '20' },
  { key: '21', label: '21' },
  { key: '22', label: '22' },
  { key: '23', label: '23' }
]

const minutesOfHour = [
  { key: '1', label: '1' },
  { key: '2', label: '2' },
  { key: '3', label: '3' },
  { key: '4', label: '4' },
  { key: '5', label: '5' },
  { key: '6', label: '6' },
  { key: '7', label: '7' },
  { key: '8', label: '8' },
  { key: '9', label: '9' },
  { key: '10', label: '10' },
  { key: '11', label: '11' },
  { key: '12', label: '12' },
  { key: '13', label: '13' },
  { key: '14', label: '14' },
  { key: '15', label: '15' },
  { key: '16', label: '16' },
  { key: '17', label: '17' },
  { key: '18', label: '18' },
  { key: '19', label: '19' },
  { key: '20', label: '20' },
  { key: '21', label: '21' },
  { key: '22', label: '22' },
  { key: '23', label: '23' },
  { key: '24', label: '24' },
  { key: '25', label: '25' },
  { key: '26', label: '26' },
  { key: '27', label: '27' },
  { key: '28', label: '28' },
  { key: '29', label: '29' },
  { key: '30', label: '30' },
  { key: '31', label: '31' },
  { key: '32', label: '32' },
  { key: '33', label: '33' },
  { key: '34', label: '34' },
  { key: '35', label: '35' },
  { key: '36', label: '36' },
  { key: '37', label: '37' },
  { key: '38', label: '38' },
  { key: '39', label: '39' },
  { key: '40', label: '40' },
  { key: '41', label: '41' },
  { key: '42', label: '42' },
  { key: '43', label: '43' },
  { key: '44', label: '44' },
  { key: '45', label: '45' },
  { key: '46', label: '46' },
  { key: '47', label: '47' },
  { key: '48', label: '48' },
  { key: '49', label: '49' },
  { key: '50', label: '50' },
  { key: '51', label: '51' },
  { key: '52', label: '52' },
  { key: '53', label: '53' },
  { key: '54', label: '54' },
  { key: '55', label: '55' },
  { key: '56', label: '56' },
  { key: '57', label: '57' },
  { key: '58', label: '58' },
  { key: '59', label: '59' }
]

const downloadTypes = {
  organization: 'Organizations',
  supplier: 'Suppliers',
  purchaseOrder: 'Purchase Orders',
  invoice: 'Invoices',
  payment: 'Payments',
  address: 'Addresses',
  remittanceLocation: 'Remittance Locations',
  supplierLocation: 'Supplier Locations',
  supplierAddress: 'Supplier Address',
  purchaseOrderHeader: 'Purchase Orders Header',
  purchaseOrderLine: 'Purchase Orders Lines',
  companyProfile: 'Company Profiles',
  companyAddress: 'Company Addresses'
}

const fileExtension = {
  '.csv': 'CSV',
  '.json': 'JSON'
}

const PIIStatus = {
  BLOCKED: 'Blocked',
  CANCELLED_BY_SUPPLIER: 'Cancelled by supplier',
  COMPLETED_WITH_ERRORS: 'Completed With Errors',
  COMPLETED: 'Completed',
  IN_PROCESS: 'In Progress',
  INTEGRATED: 'Integrated',
  INVITED: 'Invited',
  MAX_ATTEMPTS_ACHIEVED: 'Token has exceded max attempts',
  NEW: 'New',
  NOT_INVITED: 'Not invited',
  OBSOLETE: 'Obsolete',
  OPENED: 'Opened',
  PENDING: 'Pending',
  REJECTED_WITHOUT_APPROVAL: 'Rejected without approval',
  REJECTED: 'Rejected',
  SUBMITTED: 'Submitted',
  TOKEN_EXPIRED: 'Token expired',
  TOKEN_USED: 'Token used'
}

const HeaderFieldsStandard = {
  total: { pos: 4, section: 'invoiceAmount', type: 'total' },
  invoiceDate: {
    pos: 1,
    section: 'invoiceData',

    type: 'invoiceDate'
  },
  invoiceNumber: {
    pos: 0,
    section: 'invoiceData',

    type: 'invoiceNumber'
  },
  'paymentTerms.id': {
    pos: 2,
    section: 'vendorInfo',
    type: 'paymentTerms.id'
  },
  subtotal: {
    pos: 0,
    section: 'invoiceAmount',

    type: 'subtotal'
  },
  tax: { pos: 2, section: 'invoiceAmount', type: 'tax' },
  'supplier.name': {
    pos: 0,
    section: 'vendorInfo',

    type: 'supplier.name'
  },
  'supplier.id': {
    pos: 1,
    section: 'vendorInfo',
    type: 'supplier.id'
  },
  discount: {
    pos: 1,
    section: 'invoiceAmount',

    type: 'discount'
  },
  'companyProfile.billToAddress.id': {
    pos: 1,
    section: 'addressInfo',

    type: 'companyProfile.billToAddress.id'
  },
  'companyProfile.billToAddress.fullAddress': {
    pos: 1,
    section: 'addressInfo',

    type: 'companyProfile.billToAddress.fullAddress'
  },
  'companyProfile.id': {
    pos: 2,
    section: 'invoiceData',

    type: 'companyProfile.id'
  },
  poNumber: {
    pos: 3,
    section: 'invoiceData',

    type: 'poNumber'
  },
  nonPoInvoice: {
    pos: 4,
    section: 'invoiceData',

    type: 'nonPoInvoice'
  },
  'currency.code': {
    pos: 5,
    section: 'invoiceData',

    type: 'currency.code'
  },
  highPriorityFlag: {
    pos: 0,
    section: 'start',

    type: 'highPriorityFlag'
  },
  documentType: {
    pos: 1,
    section: 'start',
    type: 'documentType'
  },
  'supplier.taxId': {
    pos: 3,
    section: 'vendorInfo',
    type: 'supplier.taxId'
  },
  'companyProfile.taxId': {
    pos: 0,
    section: 'taxInfo',
    type: 'companyProfile.taxId'
  },
  'companyProfile.shipToAddress.id': {
    pos: 0,
    section: 'addressInfo',
    type: 'companyProfile.shipToAddress.id'
  },
  'companyProfile.shipToAddress.fullAddress': {
    pos: 0,
    section: 'addressInfo',
    type: 'companyProfile.shipToAddress.fullAddress'
  },
  process: { pos: 2, section: 'start', type: 'process' },
  'supplier.remitToAddress.id': {
    pos: 3,
    section: 'addressInfo',

    type: 'supplier.remitToAddress.id'
  },
  'supplier.address.id': {
    pos: 2,
    section: 'addressInfo',
    type: 'supplier.address.id'
  },
  appId: {
    pos: 2,
    section: 'start',

    type: 'appId'
  },
  charges: {
    pos: 3,
    section: 'invoiceAmount',
    type: 'charges'
  },
  withholdingTax: {
    pos: 4,
    section: 'invoiceAmount',
    type: 'withholdingTax'
  },
  'supplier.address.fullAddress': {
    pos: 2,
    section: 'addressInfo',
    type: 'supplier.address.fullAddress'
  },
  'supplier.remitToAddress.fullAddress': {
    pos: 3,
    section: 'addressInfo',
    type: 'supplier.remitToAddress.fullAddress'
  },
  contractNumber: {
    pos: 3,
    section: 'addressInfo',
    type: 'contractNumber'
  }
}

const HeaderFieldsSide = {
  VENDOR_NAME: {
    pos: 0,
    section: 'vendorInfo',
    label: 'VENDOR NAME',
    type: 'VENDOR_NAME'
  },
  TOTAL: { pos: 4, section: 'invoiceAmount', label: 'TOTAL', type: 'TOTAL' },
  RECEIVER_ADDRESS: {
    pos: 0,
    section: 'addressInfo',
    label: 'BUYER SHIP-TO ADDRESS',
    type: 'RECEIVER_ADDRESS'
  },
  INVOICE_RECEIPT_DATE: {
    pos: 1,
    section: 'invoiceData',
    label: 'INVOICE DATE',
    type: 'INVOICE_RECEIPT_DATE'
  },
  INVOICE_RECEIPT_ID: {
    pos: 0,
    section: 'invoiceData',
    label: 'INVOICE NUMBER',
    type: 'INVOICE_RECEIPT_ID'
  },
  PAYMENT_TERMS: {
    pos: 2,
    section: 'vendorInfo',
    label: 'PAYMENT TERMS',
    type: 'PAYMENT_TERMS'
  },
  SUBTOTAL: {
    pos: 0,
    section: 'invoiceAmount',
    label: 'SUBTOTAL',
    type: 'SUBTOTAL'
  },
  TAX: { pos: 2, section: 'invoiceAmount', label: 'TAX', type: 'TAX' },
  VENDOR_ID: {
    pos: 1,
    section: 'vendorInfo',
    label: 'VENDOR ID',
    type: 'VENDOR_ID'
  },
  DISCOUNT: {
    pos: 1,
    section: 'invoiceAmount',
    label: 'DISCOUNT',
    type: 'DISCOUNT'
  },
  OTHER_CHARGES: {
    pos: 3,
    section: 'invoiceAmount',
    label: 'OTHER CHARGES',
    type: 'OTHER_CHARGES'
  },
  BILL_TO: {
    pos: 1,
    section: 'addressInfo',
    label: 'BUYER BILL-TO ADDRESS',
    type: 'BILL_TO'
  },
  COMPANY_CODE: {
    pos: 2,
    section: 'invoiceData',
    label: 'COMPANY CODE',
    type: 'COMPANY_CODE'
  },
  PURCHASE_ORDER: {
    pos: 3,
    section: 'invoiceData',
    label: 'PURCHASE ORDER',
    type: 'PURCHASE_ORDER'
  },
  NON_PO: {
    pos: 4,
    section: 'invoiceData',
    label: 'NON-PO INVOICE',
    type: 'NON_PO'
  },
  CURRENCY: {
    pos: 5,
    section: 'invoiceData',
    label: 'CURRENCY',
    type: 'CURRENCY'
  },
  HIGH_PRIORITY_FLAG: {
    pos: 0,
    section: 'start',
    label: 'HIGH PRIORITY INVOICE',
    type: 'HIGH_PRIORITY_FLAG'
  },
  INVOICE_TYPE: {
    pos: 1,
    section: 'start',
    label: 'INVOICE TYPE',
    type: 'INVOICE_TYPE'
  },
  DUE_DATE: { pos: 0, label: 'DUE DATE', type: 'DUE_DATE' },
  TAX_PAYER_ID: {
    pos: 3,
    section: 'vendorInfo',
    label: 'VENDOR TAX ID',
    type: 'TAX_PAYER_ID'
  },
  TAX_RECEIVER_ID: {
    pos: 0,
    section: 'taxInfo',
    label: 'BUYER TAX ID',
    type: 'TAX_RECEIVER_ID'
  },
  SHIP_TO: { pos: 0, label: 'SHIP-TO ADDRESS', type: 'SHIP_TO' },
  AUTHORIZATION_NUMBER: {
    pos: 2,
    section: 'taxInfo',
    label: 'AUTHORIZATION NUMBER',
    type: 'AUTHORIZATION_NUMBER'
  },
  SERIAL_NUMBER: {
    pos: 3,
    section: 'taxInfo',
    label: 'SERIAL NUMBER',
    type: 'SERIAL_NUMBER'
  },
  PROCESS: { pos: 2, section: 'start', label: 'PROCESS', type: 'PROCESS' },
  SUPPLIER_REMIT_TO_ADDRESS: {
    pos: 3,
    section: 'addressInfo',
    label: 'SUPPLIER REMIT-TO ADDRESS',
    type: 'SUPPLIER_REMIT_TO_ADDRESS'
  },
  SUPPLIER_SHIP_FROM_ADDRESS: {
    pos: 2,
    section: 'addressInfo',
    label: 'SUPPLIER ADDRESS',
    type: 'SUPPLIER_SHIP_FROM_ADDRESS'
  },
  APP_ID: {
    pos: 2,
    section: 'start',
    label: 'APPLICATION',
    type: 'APP_ID'
  },
  BOLETO_NUMBER: {
    pos: 2,
    section: 'taxInfo',
    label: 'BOLETO NUMBER',
    type: 'BOLETO_NUMBER'
  }
}

const ColumnsOrderLineItemsStandard = {
  lineNumber: { pos: 0, type: 'lineNumber', section: 'column' },
  description: { pos: 2, type: 'description', section: 'column' },
  quantity: { pos: 4, type: 'quantity', section: 'column' },
  total: { pos: 10, type: 'total', section: 'column' },
  supplierPartNumber: {
    pos: 3,

    type: 'supplierPartNumber',
    section: 'column'
  },
  'uom.code': { pos: 5, type: 'uom.code', section: 'column' },
  unitPrice: { pos: 6, type: 'unitPrice', section: 'column' },
  discount: { pos: 7, type: 'discount', section: 'column' },
  taxAmount: { pos: 8, type: 'taxAmount', section: 'column' },
  additionalDetails: {
    pos: 13,
    label: 'Additional Details',
    type: 'additionalDetails',
    section: 'column'
  },
  poLineNumber: { pos: 1, type: 'poLineNumber', section: 'column' },
  accounting: { pos: -1, type: 'accounting', section: 'details' },
  'commodity.id': { pos: -1, type: 'commodity.id', section: 'details' },
  'companyProfile.shipToAddress.id': {
    pos: -1,
    type: 'companyProfile.shipToAddress.id',
    section: 'details'
  },
  'companyProfile.shipToAddress.fullAddress': {
    pos: -1,
    type: 'companyProfile.shipToAddress.fullAddress',
    section: 'details'
  },
  'supplier.address.id': {
    pos: -1,
    type: 'supplier.address.id',
    section: 'details'
  },
  'supplier.address.fullAddress': {
    pos: -1,
    type: 'supplier.address.fullAddress',
    section: 'details'
  },
  'supplier.shipToAddress.id': {
    pos: -1,
    type: 'supplier.shipToAddress.id',
    section: 'details'
  },
  'supplier.shipToAddress.fullAddress': {
    pos: -1,
    type: 'supplier.shipToAddress.fullAddress',
    section: 'details'
  },
  'companyProfile.billToAddress.id': {
    pos: -1,
    type: 'companyProfile.billToAddress.id',
    section: 'details'
  },
  'companyProfile.billToAddress.fullAddress': {
    pos: -1,
    type: 'companyProfile.billToAddress.fullAddress',
    section: 'details'
  },
  'contractItem.id': {
    pos: -1,
    type: 'contractItem.id',
    section: 'details'
  }
}

const ColumnsOrderChargesTaxesStandard = {
  type: { pos: 1, type: 'type' },
  taxCode: { pos: 1, type: 'taxCode' },
  description: { pos: 2, type: 'description' },
  total: { pos: 4, type: 'total' },
  rate: { pos: 3, rate: 'rate' }
}

const ColumnsOrderLineItems = {
  LINE_NUMBER: { pos: 0, label: 'Invoice Line', type: 'LINE_NUMBER', section: 'column' },
  ITEM: { pos: 2, label: 'Description', type: 'ITEM', section: 'column' },
  QUANTITY: { pos: 4, label: 'Quantity', type: 'QUANTITY', section: 'column' },
  PRICE: { pos: 10, label: 'Amount', type: 'PRICE', section: 'column' },
  SUPPLIER_PART_NUMBER: {
    pos: 3,
    label: 'Supplier Part',
    type: 'SUPPLIER_PART_NUMBER',
    section: 'column'
  },
  UNIT_OF_MEASURE: { pos: 5, label: 'Unit of Measure', type: 'UNIT_OF_MEASURE', section: 'column' },
  UNIT_PRICE: { pos: 6, label: 'Unit Price', type: 'UNIT_PRICE', section: 'column' },
  DISCOUNT: { pos: 7, label: 'Discount', type: 'DISCOUNT', section: 'column' },
  TAX_AMOUNT: { pos: 8, label: 'Tax Amount', type: 'TAX_AMOUNT', section: 'column' },
  TAX_PERCENTAGE: { pos: 9, label: 'Tax %', type: 'TAX_PERCENTAGE', section: 'column' },
  PO_LINE_NUMBER: { pos: 1, label: 'PO Line', type: 'PO_LINE_NUMBER', section: 'column' },
  SUPPLIER_SHIP_FROM_ADDRESS: {
    pos: -1,
    label: 'Supplier Address',
    type: 'SUPPLIER_SHIP_FROM_ADDRESS',
    id: 'SUPPLIER_SHIP_FROM_ADDRESS_ID',
    section: 'details'
  },
  ADDITIONAL_DETAILS: {
    pos: 13,
    label: 'Additional Details',
    type: 'ADDITIONAL_DETAILS',
    section: 'column'
  },
  BUYER_SHIP_TO_ADDRESS: {
    pos: -1,
    label: 'Buyer Ship-To Address',
    type: 'BUYER_SHIP_TO_ADDRESS',
    id: 'BUYER_SHIP_TO_ADDRESS_ID',
    section: 'details'
  },
  ACCOUNTING: {
    pos: -1,
    label: 'Accounting Information',
    type: 'ACCOUNTING',
    section: 'details'
  },
  COMMODITY_ID: {
    pos: -1,
    label: 'Commodity',
    type: 'COMMODITY_ID',
    section: 'details'
  }
}

const ColumnsOrderAdditionalCharges = {
  CHARGE_TYPE: { pos: 1, label: 'Type' },
  DESCRIPTION: { pos: 2, label: 'Description' },
  TOTAL: { pos: 4, label: 'Amount' },
  RATE: { pos: 3, label: 'Rate' }
}

const Taxes = {
  salesTax: { value: 'salesTax', label: 'Sales Tax' },
  pstTax: { value: 'pstTax', label: 'PST Tax' },
  gstTax: { value: 'gstTax', label: 'GST Tax' },
  hstTax: { value: 'hstTax', label: 'HST Tax' },
  vatTax: { value: 'vatTax', label: 'Vat' },
  jctTax: { value: 'jctTax', label: 'JCT Tax' },
  sgstTax: { value: 'sgstTax', label: 'SGST Tax' },
  cgstTax: { value: 'cgstTax', label: 'CGST Tax' },
  igstTax: { value: 'igstTax', label: 'IGST Tax' }
}

const AdditionalCharges = {
  handlingCharge: { value: 'handlingCharge', label: 'Handling Charges' },
  shippingCharges: { value: 'shippingCharges', label: ' Shipping Charges' },
  miscellaneousCharge: { value: 'miscellaneousCharge', label: 'Misc Charge' }
}

const CustomCharges = {
  insuranceCharge: { value: 'insuranceCharge', label: 'Insurance Charge' },
  otherCharge: { value: 'otherCharge', label: 'Other Charge' }
}

const specialPOLines = [
  Taxes.salesTax,
  Taxes.pstTax,
  Taxes.gstTax,
  Taxes.hstTax,
  Taxes.jctTax,
  Taxes.vatTax,
  AdditionalCharges.shippingCharges,
  AdditionalCharges.handlingCharge
]

const specialPOLinesByProcess = {
  ocr: [Taxes.salesTax, AdditionalCharges.shippingCharges, AdditionalCharges.handlingCharge, Taxes.pstTax, Taxes.gstTax, Taxes.hstTax],
  northAmericaValidation: [
    Taxes.salesTax,
    Taxes.pstTax,
    Taxes.gstTax,
    Taxes.hstTax,
    AdditionalCharges.shippingCharges,
    AdditionalCharges.handlingCharge
  ],
  'ocr-global': [AdditionalCharges.shippingCharges, AdditionalCharges.handlingCharge],
  mexicanValidation: [Taxes.vatTax, AdditionalCharges.shippingCharges, AdditionalCharges.handlingCharge],
  gtValidation: [AdditionalCharges.shippingCharges, AdditionalCharges.handlingCharge],
  brValidation: [Taxes.vatTax, AdditionalCharges.shippingCharges, AdditionalCharges.handlingCharge],
  svValidation: [Taxes.vatTax, AdditionalCharges.shippingCharges, AdditionalCharges.handlingCharge],
  emeaValidation: [
    Taxes.salesTax,
    Taxes.pstTax,
    Taxes.gstTax,
    Taxes.hstTax,
    Taxes.vatTax,
    AdditionalCharges.shippingCharges,
    AdditionalCharges.handlingCharge
  ],
  apacValidation: [
    Taxes.salesTax,
    Taxes.pstTax,
    Taxes.gstTax,
    Taxes.hstTax,
    Taxes.jctTax,
    Taxes.vatTax,
    Taxes.sgstTax,
    Taxes.cgstTax,
    Taxes.igstTax,
    AdditionalCharges.shippingCharges,
    AdditionalCharges.handlingCharge
  ],
  latamValidation: [Taxes.salesTax, AdditionalCharges.shippingCharges, AdditionalCharges.handlingCharge]
}

const chargesTypesByProcess = {
  ocr: [AdditionalCharges.shippingCharges, AdditionalCharges.handlingCharge],
  northAmericaValidation: [AdditionalCharges.shippingCharges, AdditionalCharges.handlingCharge],
  'ocr-global': [AdditionalCharges.shippingCharges, AdditionalCharges.handlingCharge],
  mexicanValidation: [AdditionalCharges.shippingCharges, AdditionalCharges.handlingCharge],
  gtValidation: [AdditionalCharges.shippingCharges, AdditionalCharges.handlingCharge],
  brValidation: [AdditionalCharges.shippingCharges, AdditionalCharges.handlingCharge],
  svValidation: [AdditionalCharges.shippingCharges, AdditionalCharges.handlingCharge],
  emeaValidation: [AdditionalCharges.shippingCharges, AdditionalCharges.handlingCharge],
  apacValidation: [AdditionalCharges.shippingCharges, AdditionalCharges.handlingCharge],
  latamValidation: [AdditionalCharges.shippingCharges, AdditionalCharges.handlingCharge]
}

const taxesTypesByProcess = {
  ocr: [Taxes.salesTax],
  northAmericaValidation: [Taxes.salesTax, Taxes.pstTax, Taxes.gstTax, Taxes.hstTax],
  'ocr-global': [],
  mexicanValidation: [Taxes.vatTax],
  gtValidation: [],
  brValidation: [Taxes.vatTax],
  svValidation: [Taxes.vatTax],
  emeaValidation: [Taxes.salesTax, Taxes.pstTax, Taxes.gstTax, Taxes.hstTax, Taxes.vatTax],
  apacValidation: [Taxes.salesTax, Taxes.pstTax, Taxes.gstTax, Taxes.hstTax, Taxes.vatTax],
  latamValidation: [Taxes.salesTax]
}

const ChargesBySystem = {
  ariba: [],
  coupa: [AdditionalCharges.miscellaneousCharge],
  ivalua: [],
  generic: []
}

const invoiceSubstatus = {
  invoiceSupplierException: 'invoiceSupplierException'
}

const ServiceText = {
  ariba: 'Go to Ariba',
  coupa: 'Go to Coupa',
  generic: 'Go to Invoice',
  workday: 'Go to Workday'
}

const ServiceType = {
  ariba: 'Ariba',
  coupa: 'Coupa',
  ivalua: 'Ivalua',
  generic: 'Generic',
  s4Hana: 'S/4 Hana',
  workday: 'Workday Financials',
  wss: 'Workday Strategic Sourcing',
  ics: 'ICS'
}

const typeField = {
  number: 'Number',
  date: 'Date',
  string: 'String',
  currency: 'Currency',
  integer: 'Integer',
  plantLocation: 'Company Address',
  alphanumeric: 'Alphanumeric',
  positiveNumber: 'Positive Number'
}

const confidenceInterval = {
  veryHigh: 'Very High',
  high: 'High',
  medium: 'Medium',
  low: 'Low'
}

const levelField = {
  header: 'Header',
  lineItems: 'Line Items',
  additionalCharges: 'Additional Charges'
}

const DocumentStatus = {
  new: 'New',
  ignored: 'Ignored',
  processing: 'Processing',
  completed: 'Completed',
  rejected: 'Rejected',
  failed: 'Failed',
  invoiceException: 'Invoice Exception'
}

const DocumentSubStatus = {
  sentToExternalSystem: 'Sent To External System',
  sentToAdditionalInbox: 'Sent To additional Inbox',
  reconciling: 'Reconciling',
  rejected: 'Rejected',
  invoiceSupplierException: 'Supplier Exception',
  duplicateInvoice: 'Duplicate Invoice',
  submitted: 'Submitted',
  failedToSend: 'Failed To Send'
}

const AribaDocumentSubStatus = {
  rejectedNotified: 'Rejected Notified',
  sentToAriba: 'Sent To Ariba',
  unprocessed: 'Unprocessed',
  rejecting: 'Rejecting',
  paying: 'Paying',
  paid: 'Paid',
  failedRejecting: 'Failed Rejecting',
  failedPaying: 'Failed Paying',
  canceled: 'Canceled',
  invalid: 'Invalid',
  awaitingExternalResolution: 'Awaiting External Resolution',
  failedExternalPush: 'Failed External Push',
  cCInvoiceToAN: 'CC Invoice To AN',
  submittedWithErrors: 'Submitted With Errors',
  awaitingReceipts: 'Awaiting Receipts',
  submitted: 'Submitted',
  reconciled: 'Reconciled',
  composing: 'Composing'
}

const CoupaDocumentSubStatus = {
  sentToCoupa: 'Sent To Coupa',
  submitted: 'Submitted',
  failedToSubmit: 'Failed To Submit'
}

const InvoiceOriginsAriba = {
  aribaNetwork: 'Ariba Network',
  aribaBuyer: 'Ariba B&I'
}

const InvoiceOrigins = {
  email: 'Email',
  mobileApp: 'Mobile App',
  fileImport: 'File Import'
}

const CfdiStatus = {
  notValidated: 'Not Validated',
  valid: 'Valid',
  notValid: 'Not Valid'
}

const InvoiceType = {
  invoice: 'Invoice',
  creditMemo: 'Credit Memo',
  paymentRequest: 'Payment Request'
}

const integrationsInsights = {
  ariba: {
    title: 'Ariba Integrations',
    integrations: [
      {
        name: 'Operational Reporting for Procurement',
        api: 'operationalReportingProcurementApi'
      },
      {
        name: 'Analytical Reporting',
        api: 'analyticalReportingApi'
      },
      {
        name: 'Operational Reporting for Sourcing',
        api: 'operationalReportingSourcingApi'
      },
      {
        name: 'Supplier Data With Pagination',
        api: 'supplierDataPaginationApi'
      }
    ]
  },
  coupa: {
    title: 'Coupa Integrations',
    integrations: [
      {
        name: 'Core',
        api: 'core'
      }
    ]
  }
}

const fieldsByType = {
  s3: [
    { key: 'accessKeyId', label: 'Access Key Id', type: 'password' },
    {
      key: 'secretAccessKey',
      label: 'Secret Access Key',
      type: 'password'
    },
    { key: 'bucketName', label: 'Bucket Name', type: 'text', readOnly: true },
    { key: 'path', label: 'Path', type: 'text', readOnly: false },
    {
      key: 'fileExtension',
      label: 'File Extension',
      type: 'select',
      readOnly: false,
      values: [{ label: 'JSON', key: '.json' }]
    },
    { key: 'zipFile', label: 'Zip', type: 'boolean', readOnly: false }
  ],
  sharepoint: [
    { key: 'tenantId', label: 'Tenant Id', type: 'sp' },
    { key: 'siteUrl', label: 'Site Url', type: 'text', readOnly: false },
    { key: 'path', label: 'Path', type: 'text', readOnly: false },
    {
      key: 'fileExtension',
      label: 'File Extension',
      readOnly: false,
      type: 'select',
      values: [
        { label: 'JSON', key: '.json' },
        { label: 'CSV', key: '.csv' },
        { label: 'TXT', key: '.txt' },
        { label: 'ZIP', key: '.zip' }
      ]
    },
    { key: 'zipFile', label: 'Zip', type: 'boolean', readOnly: false }
  ],
  sapHana: [
    { key: 'dbHost', label: 'Host', type: 'text', readOnly: true },
    { key: 'dbPort', label: 'Port', type: 'text', readOnly: true },
    { key: 'dbSchema', label: 'Schema', type: 'text', readOnly: true },
    { key: 'dbUser', label: 'User', type: 'text', readOnly: true },
    { key: 'dbPassword', label: 'Password', type: 'password' }
  ],
  msSql: [
    { key: 'dbName', label: 'DB Name', type: 'text', readOnly: true },
    { key: 'dbPort', label: 'DB Port', type: 'number', readOnly: true },
    { key: 'dbServer', label: 'DB Server', type: 'text', readOnly: true },
    { key: 'dbDomain', label: 'DB domain', type: 'text', readOnly: true },
    { key: 'dbUser', label: 'User', type: 'text', readOnly: true },
    { key: 'dbPassword', label: 'Password', type: 'password' }
  ],
  sftp: [
    { key: 'host', label: 'Host', type: 'text', readOnly: true },
    { key: 'user', label: 'User', type: 'text', readOnly: true },
    { key: 'path', label: 'Path', type: 'text', readOnly: false },
    { key: 'port', label: 'Port', type: 'text', readOnly: true },
    {
      key: 'encryptionMethod',
      label: 'Encryption Method',
      type: 'select',
      values: [
        { label: 'No Encryption', key: 'noencryption' },
        { label: 'PGP', key: 'pgp' }
      ],
      readOnly: true
    },
    {
      key: 'encryptionFile',
      label: 'Encryption File',
      inputCondition: 'encryptionMethod',
      valueCondition: 'pgp',
      conditional: true,
      type: 'file',
      values: '.asc, .txt'
    },
    {
      key: 'fileExtension',
      label: 'File Extension',
      type: 'select',
      values: [
        { label: 'JSON', key: '.json' },
        { label: 'CSV', key: '.csv' },
        { label: 'TXT', key: '.txt' }
      ],
      readOnly: false
    },
    { key: 'singleConnection', label: 'Single Connection', type: 'boolean', readOnly: true },
    { key: 'zipFile', label: 'Zip', type: 'boolean', readOnly: false },
    {
      key: 'encryptionFileObj'
    }
  ],
  default: [
    { key: 'name', label: 'Connection Name', type: 'text' },
    { key: 'connectionId', label: 'Connection Id', type: 'text' },
    { key: 'type', label: 'Type', type: 'text' }
  ]
}

const fieldsByService = {
  ariba: [
    { key: 'realm', label: 'Realm', type: 'text' },
    { key: 'aribaNetworkId', label: 'Ariba Network Id', type: 'text' },
    { key: 'apiKey', label: 'Ariba Application Key', type: 'password' },
    { key: 'clientId', label: 'Ariba Client Id', type: 'password' },
    { key: 'secret', label: 'Ariba Application Secret', type: 'password' }
  ],
  coupa: [
    { key: 'siteUrl', label: 'Site Url', type: 'text' },
    { key: 'clientId', label: 'Client Id', type: 'password' },
    { key: 'clientSecret', label: 'Client Secret', type: 'password' }
  ],
  aribaItk: [
    { key: 'realm', label: 'Realm', type: 'text' },
    {
      key: 'siteRegion',
      label: 'SAP Ariba Site Region',
      type: 'select',
      values: [
        { label: 'https://s1.ariba.com', key: 's1' },
        { label: 'https://s2.ariba.com', key: 's2' },
        { label: 'https://s3.ariba.com', key: 's3' }
      ]
    },
    {
      key: 'authenticationType',
      label: 'Ariba Authentication Type',
      type: 'select',
      values: [
        { label: 'Shared Secret', key: 'sharedSecret' },
        { label: 'Certificate', key: 'certificate' }
      ]
    },
    {
      key: 'certificate',
      label: 'Ariba Certificate',
      inputCondition: 'authenticationType',
      valueCondition: 'certificate',
      conditional: true,
      type: 'conditional',
      typeInput: 'text',
      multiline: true,
      rows: '15'
    },
    {
      key: 'sharedSecret',
      label: 'Shared Secret',
      inputCondition: 'authenticationType',
      valueCondition: 'sharedSecret',
      conditional: true,
      type: 'conditional',
      typeInput: 'password',
      multiline: false
    }
  ],
  s4Hana: [
    { key: 'siteUrl', label: 'Site Url', type: 'text' },
    { key: 'queryParams', label: 'Params', type: 'text' },
    { key: 'type', label: 'Type', type: 'select', values: [{ label: 'Basic', key: 'basic' }] },
    {
      key: 'user',
      label: 'User',
      inputCondition: 'type',
      valueCondition: 'basic',
      conditional: true,
      type: 'conditional',
      typeInput: 'password'
    },
    {
      key: 'password',
      label: 'Password',
      inputCondition: 'type',
      valueCondition: 'basic',
      conditional: true,
      type: 'conditional',
      typeInput: 'password'
    },
    { key: 'headerParamsEnabled', label: 'Add Header Params', type: 'boolean' },
    {
      key: 'headerParams',
      label: 'Header Params',
      inputCondition: 'headerParamsEnabled',
      valueCondition: true,
      conditional: true,
      type: 'conditional',
      typeInput: 'arrayKeyValue'
    }
  ],
  workday: {
    restConnectionApi: [
      { key: 'siteUrl', label: 'Site Url', type: 'text' },
      { key: 'type', label: 'Type', type: 'select', values: [{ label: 'Basic', key: 'basic' }] },
      {
        key: 'user',
        label: 'User',
        inputCondition: 'type',
        valueCondition: 'basic',
        conditional: true,
        type: 'conditional',
        typeInput: 'password'
      },
      {
        key: 'password',
        label: 'Password',
        inputCondition: 'type',
        valueCondition: 'basic',
        conditional: true,
        type: 'conditional',
        typeInput: 'password'
      },
      {
        key: 'workdayTenant',
        label: 'Workday Tenant',
        inputCondition: 'type',
        valueCondition: 'basic',
        conditional: true,
        type: 'conditional',
        typeInput: 'text'
      },
      {
        key: 'refreshToken',
        label: 'Refresh Token',
        inputCondition: 'type',
        valueCondition: 'basic',
        conditional: true,
        type: 'conditional',
        typeInput: 'text'
      }
    ],
    soapConnectionApi: [
      { key: 'siteUrl', label: 'Site Url', type: 'text' },
      { key: 'type', label: 'Type', type: 'select', values: [{ label: 'Basic', key: 'basic' }] },
      {
        key: 'user',
        label: 'User',
        inputCondition: 'type',
        valueCondition: 'basic',
        conditional: true,
        type: 'conditional',
        typeInput: 'password'
      },
      {
        key: 'password',
        label: 'Password',
        inputCondition: 'type',
        valueCondition: 'basic',
        conditional: true,
        type: 'conditional',
        typeInput: 'password'
      }
    ],
    raasApi: [
      { key: 'siteUrl', label: 'Site Url', type: 'text' },
      { key: 'type', label: 'Type', type: 'select', values: [{ label: 'Basic', key: 'basic' }] },
      {
        key: 'user',
        label: 'User',
        inputCondition: 'type',
        valueCondition: 'basic',
        conditional: true,
        type: 'conditional',
        typeInput: 'password'
      },
      {
        key: 'password',
        label: 'Password',
        inputCondition: 'type',
        valueCondition: 'basic',
        conditional: true,
        type: 'conditional',
        typeInput: 'password'
      },
      { key: 'name', label: 'Name', type: 'text', section: 'accounting' },
      { key: 'concatChar', label: 'Concat Char', type: 'text', section: 'accounting' },
      {
        key: 'workdayTags',
        label: 'Workdaytags Configuration',
        type: 'workdayTags',
        typeInput: 'workdayTags',
        section: 'accounting'
      }
    ]
  },
  aribaNetworkPurchaseOrdersBuyer: [
    { key: 'aribaNetworkId', label: 'Ariba Network Id', type: 'text' },
    { key: 'apiKey', label: 'Ariba Application Key', type: 'password' },
    { key: 'clientId', label: 'Ariba Client Id', type: 'password' },
    { key: 'secret', label: 'Ariba Application Secret', type: 'password' }
  ],
  aribaNetworkInvoiceHeaderDataExtraction: [
    { key: 'aribaNetworkId', label: 'Ariba Network Id', type: 'text' },
    { key: 'apiKey', label: 'Ariba Application Key', type: 'password' },
    { key: 'clientId', label: 'Ariba Client Id', type: 'password' },
    { key: 'secret', label: 'Ariba Application Secret', type: 'password' }
  ]
}

const ViewComponentShownByExternalFactors = {
  INVOICESAI: {
    IGNORE_DOCUMENTS: {
      IGNORE_MODAL: {
        CREATE_RULE_DROPDOWN_OPTION: {
          PROCESS: ['*'],
          ORIGIN: ['email'],
          STATUS: ['*']
        }
      }
    }
  }
}

const googleConstantsAPI = {
  clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  appId: process.env.REACT_APP_GOOGLE_APP_ID
}

const editInvoicesAI = ['*', 'InvoiceAI.*', 'InvoiceAI.Invoices.*', 'InvoiceAI.Invoices.EditInvoices']

const PUBLIC_S3_ASSETS = 'https://relish-public-assets.s3.us-west-2.amazonaws.com'
const ServiceProviderOptions = { microsoftAccount: 'Microsoft' }
const InvoiceOriginOptions = {
  email: { key: 'email', label: 'Email', origin: 'email' },
  peppolNetwork: { key: 'peppolNetwork', label: 'Api Peppol Network', origin: 'api' }
}

const SupplierStatus = {
  SUPPLIER_NOT_MATCHED: 'SupplierNotMatched',
  SUPPLIER_BYPASSED: 'SupplierBypassed',
  SUPPLIER_MATCHED: 'SupplierMatched',
  ENRICHMENT_FOUND: 'EnrichmentFound',
  SUPPLIER_NOT_FOUND: 'SupplierNotFound'
}

const ignoreInvoiceViewsAriba = {
  ics: {
    downstream: ['Export Purchase Orders']
  }
}

const aribaParamsConfigurationType = {
  analytics: 'analyticalReportingApi',
  procurement: 'operationalReportingProcurementApi',
  sourcing: 'operationalReportingSourcingApi',
  itk_upstream: 'sourcingApi',
  itk_downstream: 'buyerItkApi',
  sourcingMasterData: 'masterDataRetrievalSourcingApi',
  procurementMasterData: 'masterDataRetrievalProcurementApi',
  aribaNetworkPurchaseOrdersBuyer: 'aribaNetworkPurchaseOrdersApi',
  aribaNetworkInvoiceHeaderDataExtraction: 'aribaNetworkInvoiceHeaderDataExtractionApi'
}

export {
  AdditionalCharges,
  AddressValidaton,
  ApiAdmin,
  ApiAdminInsights,
  ApiAdminInvoice,
  ApiAdminPricebook,
  ApiCoupa,
  ApiDataAssure,
  ApiDataAssureLogs,
  ApiDnbConnector,
  ApiEmailService,
  ApiNitorConnect,
  ApiPii,
  ApiRouter,
  AribaDocumentSubStatus,
  Auth0,
  AuthService,
  CfdiStatus,
  ChargesBySystem,
  ColumnsOrderAdditionalCharges,
  ColumnsOrderChargesTaxesStandard,
  ColumnsOrderLineItems,
  ColumnsOrderLineItemsStandard,
  ConnectionTypes,
  CoupaDocumentSubStatus,
  CustomCharges,
  DABulkTemplate,
  DocumentStatus,
  DocumentSubStatus,
  HeaderFieldsSide,
  HeaderFieldsStandard,
  InputDefault,
  Insights,
  InvoiceOrigins,
  InvoiceOriginsAriba,
  InvoiceProcess,
  InvoiceProcessFilter,
  InvoiceType,
  LoginUser,
  MessagesTypes,
  PIIStatus,
  PUBLIC_S3_ASSETS,
  ServiceText,
  ServiceType,
  Taxes,
  ViewComponentShownByExternalFactors,
  ViewsPermissions,
  Web,
  aribaApiTypes,
  chargesTypesByProcess,
  confidenceInterval,
  dayOfMonth,
  dayOfWeek,
  dayOfWeekDictionary,
  downloadTypes,
  editInvoicesAI,
  enabledApps,
  entities,
  fieldsByService,
  fieldsByType,
  fileExtension,
  frequency,
  googleConstantsAPI,
  hoursOfDay,
  insightsDeluxeTitles,
  insightsTitles,
  integrationsInsights,
  invoiceProcessType,
  invoiceStatus,
  invoiceSubstatus,
  invoiceTitles,
  invoiceViewsAriba,
  levelField,
  loadStatus,
  logLevelValues,
  minutesOfHour,
  piiValidationTypes,
  piiValidationUpdateOptions,
  specialPOLines,
  specialPOLinesByProcess,
  statusHistory,
  SupplierStatus,
  taxesTypesByProcess,
  transactionLogType,
  transactionStatus,
  typeField,
  typeLoad,
  updateFrequency,
  validationTypes,
  viewsAriba,
  weeksOfYear,
  ServiceProviderOptions,
  InvoiceOriginOptions,
  ignoreInvoiceViewsAriba,
  aribaParamsConfigurationType
}
